import React from 'react';

class Community extends React.Component {
    render() {
        return (
            <div class="content m-0 p-0 w-100">
                <div class="container-fluid w-100 p-0 mb-2 mb-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div
                                    class="hero-img-height hero-img-cover-top"
                                    style={{
                                        backgroundImage:
                                            'url(/assets/img/info/ZB_containers_community.jpg)',
                                        filter: 'brightness(70%)',
                                    }}
                                    alt="Woman at podium speaking to audience."
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row m-2 mb-6">
                        <div class="title col-12 col-md-8">
                            <h2 class="align-center pb-3 mbr-fonts-style display-3">
                                Community and Public Officials
                            </h2>
                            <h3 class="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">
                                Zerobase is a free, privacy-first contact
                                tracing platform that empowers both individuals
                                and local officials to stop the spread of
                                COVID-19.
                            </h3>
                        </div>
                    </div>
                    <div class="row m-2 mb-md-5">
                        <div class="col-lg-8 col-12 pr-lg-5">
                            <p class="h1 mb-3" style={{ fontSize: '36px' }}>
                                Why should you partner with Zerobase?
                            </p>
                        </div>
                    </div>
                    <div class="container">
                        <div class="media-container-row mt-md-5">
                            <div class="col-lg-12 col-12">
                                <div class="media-container-row">
                                    <div class="media-content">
                                        <div class="mbr-section-text">
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style mb-3"
                                                style={{ fontSize: '24px' }}
                                            >
                                                <strong>
                                                    Because it’s your
                                                    community’s first line of
                                                    defense.
                                                </strong>{' '}
                                                Zerobase deploys contact tracing
                                                across a community in a way that
                                                is anonymous, fast, and easy to
                                                use. You can allocate your time
                                                and resources more efficiently,
                                                elevating your community’s
                                                ability to contain the virus. We
                                                provide you with a powerful way
                                                to instantaneously track
                                                interactions with exposed
                                                individuals without invading
                                                their privacy.
                                            </p>
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style mb-3"
                                                style={{ fontSize: '24px' }}
                                            >
                                                {' '}
                                                Zerobase empowers you to take
                                                action with conviction, such as
                                                recommending that an exposed
                                                individual self-quarantine or be
                                                registered for a COVID-19 test.
                                                Zerobase is also an early
                                                warning system to alert you when
                                                an outbreak begins so that you
                                                can avoid a blanket lockdown
                                                while quickly and effectively
                                                deploying strategic public
                                                health resources to the places
                                                that need them the most.
                                            </p>
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style mb-3"
                                                style={{ fontSize: '24px' }}
                                            >
                                                The sooner you partner with
                                                Zerobase, the stronger your
                                                check-in data repository
                                                becomes, allowing you to make
                                                each test administered within
                                                your community exponentially
                                                more powerful, especially in
                                                places with critical public
                                                health resource limitations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-container-row mt-md-5">
                            <div class="col-lg-12 col-12">
                                <div class="media-container-row">
                                    <div class="media-content">
                                        <div class="mbr-section-text">
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style"
                                                style={{ fontSize: '24px' }}
                                            >
                                                <strong>
                                                    Because contact tracing
                                                    contains outbreaks.
                                                </strong>{' '}
                                                Research demonstrates that
                                                real-time contact tracing is
                                                enough to control an outbreak of
                                                COVID-19 in a matter of weeks.
                                                If an individual is exposed to
                                                COVID-19, contact tracing allows
                                                us to pinpoint any number of
                                                others who were in the same
                                                place – such as a community
                                                center – as the exposed
                                                individual. Community healthcare
                                                providers, with the help of
                                                Zerobase, can then provide
                                                personalized recommendations to
                                                potentially-affected people in
                                                order to stop the spread with
                                                precision.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-container-row mt-6">
                            <div class="col-lg-12 col-12">
                                <div class="media-container-row">
                                    <div class="media-content">
                                        <div class="mbr-section-text">
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style"
                                                style={{ fontSize: '24px' }}
                                            >
                                                <strong>
                                                    Because it reduces
                                                    resurgence.
                                                </strong>{' '}
                                                Zerobase helps contain the
                                                spread of infection and
                                                resurgence through automated
                                                notifications of an individual’s
                                                interactions when they are
                                                likely infected. Epidemiologists
                                                have found that minimizing the
                                                time between symptom onset and
                                                isolation is the most critical
                                                part of controlling an outbreak.
                                                Zerobase detects possible
                                                exposure to the virus days
                                                before symptoms begin, and can
                                                recommend proactive isolation
                                                that will prevent a resurgence
                                                without resorting to a full
                                                lockdown.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-container-row mt-6">
                            <div class="col-lg-12 col-12">
                                <div class="media-container-row">
                                    <div class="media-content">
                                        <div class="mbr-section-text">
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style"
                                                style={{ fontSize: '30px' }}
                                            >
                                                <strong>
                                                    The best time to implement
                                                    Zerobase in your community
                                                    was last week.
                                                </strong>
                                            </p>
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style"
                                                style={{ fontSize: '30px' }}
                                            >
                                                <strong>
                                                    The second best time is now.
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div class="container">
                            <div class="row m-4 p-5 mt-5 border-top border-secondary pb-4 pt-4">
                                <div class="col-lg-8 col-12 pr-lg-5 mt-6">
                                    <p class="h1 lh-180 mb-3">
                                        Lead your community in the fight against
                                        COVID-19.
                                    </p>
                                    <p class="h2 font-weight-normal">
                                        Zerobase is free, it’s ready, and it
                                        saves lives.
                                    </p>
                                </div>
                                <div class="col-lg-4 col-12 align-middle mt-6">
                                    <a
                                        class="btn btn-primary btn-lg align-middle"
                                        href="/assets/docs/zerobase-bluf.pdf"
                                        target="_blank"
                                    >
                                        See our executive sheet
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Community;
