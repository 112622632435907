import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { CardDeck, Card, Container } from 'react-bootstrap';
import { Flags } from 'react-feature-flags';
import InfoCard from '../check-in/components/InfoCard';
import configure from 'Images/configure';
import reports from 'Images/reports';
import removeMe from 'Images/clipboards';

const AdminPortal = () => {
    return (
        <Container className="text-center">
            <h1 className="bg-dark text-white mt-5 py-5 rounded">
                Welcome to contact tracing management
            </h1>
            {/* prettier-ignore */}
            <Container className="px-3 pb-3">
                <CardDeck>
                    <Flags authorizedFlags={['dashboards']}
                        renderOff={() => 
                            <Link className="w-50" to="/admin/qrcodes">
                            <InfoCard
                                cardClass="border-secondary"
                                imageWidth="250"
                                imageHeight="200"
                                image={configure}
                                imageClass="mx-auto mt-2"
                                bodyColor="white"
                            >
                                <Card.Title>
                                    Configure and Print QR Codes
                                </Card.Title>
                                <Card.Text>
                                    Set up your Sites, the Locations for QR code
                                    check-ins, and print out QR check-in posters
                                </Card.Text>
                            </InfoCard>
                            </Link>
                        }
                        renderOn={() => 
                            <Link className="w-25" to="/admin/qrcodes">
                            <InfoCard
                                cardClass="border-secondary"
                                imageWidth="250"
                                imageHeight="200"
                                image={configure}
                                imageClass="mx-auto mt-2"
                                bodyColor="white"
                            >
                                <Card.Title>
                                    Configure and Print QR Codes
                                </Card.Title>
                                <Card.Text>
                                    Set up your Sites, the Locations for QR code
                                    check-ins, and print out QR check-in posters
                                </Card.Text>
                            </InfoCard>
                        </Link>
                        }
                    />
                    <Link className="w-50" to="/admin/tests">
                        <InfoCard
                            cardClass="border-secondary"
                            imageWidth="250"
                            imageHeight="200"
                            image={reports}
                            imageClass="mx-auto"
                            bodyColor="white"
                        >
                            <Card.Title>Enter and Manage Reports</Card.Title>
                            <Card.Text>
                                Enter and track positive COVID-19 test reports
                                from your teammates
                            </Card.Text>
                        </InfoCard>
                    </Link>
                    <Flags authorizedFlags={['dashboards']}>
                        <Link className="w-25" to="/admin/dashboard" >
                            <InfoCard
                                cardClass="border-secondary"
                                imageWidth="250"
                                imageHeight="200"
                                image={removeMe}
                                imageClass="mx-auto"
                                bodyColor="white"
                            >
                                <Card.Title>View Trends and Data</Card.Title>
                                <Card.Text>
                                    Quickly see the important information about
                                    problem areas and how COVID-19 is affecting your
                                    workplace
                                </Card.Text>
                            </InfoCard>
                        </Link>
                    </Flags>
                </CardDeck>
            </Container>
        </Container>
    );
};

export default withRouter(AdminPortal);
