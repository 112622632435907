export default {
    ENV: process.env.NODE_ENV,
    API_HOST: process.env.REACT_APP_API_HOST
        ? process.env.REACT_APP_API_HOST
        : `${window.location.origin}/api`,
    FEATURE_FLAGS: [
        {
            name: 'dashboards',
            isActive: process.env.REACT_APP_ENABLE_DASHBOARDS === 'true',
        },
    ],
};
