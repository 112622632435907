import React from 'react';
import { node } from 'prop-types';
import { Card } from 'react-bootstrap';
import zb_logo from '../../../../assets/img/branding/zb/zb_logo';

const OrganizationCard = ({ children }) => {
    return (
        <Card className="mt-5 mb-0 text-center border-0">
            <Card.Img
                variant="top"
                src={zb_logo}
                alt="Zerobase Logo"
                className="mx-auto d-block w-75"
            />
            <Card.Body>{children}</Card.Body>
        </Card>
    );
};

OrganizationCard.propTypes = {
    children: node,
};

export default OrganizationCard;
