import React from 'react';
import { Modal } from 'react-bootstrap';

const PermissionsModal = ({ modalShowing, setModalShowing }) => {
    return (
        <Modal
            show={modalShowing}
            onHide={setModalShowing}
            animation={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center">
                    Insufficient Permissions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This app does not have permission to access your camera. You may
                need to check your site permissions and try again.
            </Modal.Body>
        </Modal>
    );
};

export default PermissionsModal;
