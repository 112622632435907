import React from 'react';

class NotFound extends React.Component {
    render() {
        return (
            <div class="content">
                <div class="container" style={{ padding: '0' }}>
                    <div class="jumbotron text-center">
                        <h1>404</h1>
                        <p class="lead">
                            Sorry, the page you requested can't be found.
                        </p>
                        <a class="btn btn-default" href="/">
                            Back to home
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
