import React from 'react';

class Feedback extends React.Component {
    render() {
        return (
            <div class="content">
                <div class="container" style={{ padding: '0' }}>
                    <iframe
                        class="airtable-embed airtable-dynamic-height"
                        src="https://airtable.com/embed/shrhNLnCPVYpwihS2?backgroundColor=pink"
                        frameborder="0"
                        onmousewheel=""
                        width="100%"
                        height="911"
                        style={{
                            background: 'transparent',
                            border: '1px solid #ccc',
                        }}
                        title="Feedback"
                    ></iframe>
                </div>
            </div>
        );
    }
}

export default Feedback;
