import React from 'react';
import { Card } from 'react-bootstrap';

const InfoCard = ({
    cardClass,
    image,
    imageClass,
    imageWidth,
    imageHeight,
    bodyColor,
    children,
}) => {
    return (
        <Card className={`${cardClass} h-100`}>
            <Card.Img
                className={imageClass}
                width={imageWidth}
                height={imageHeight}
                variant="top"
                src={image}
                alt="placeholder image"
            />
            <Card.Body className={`bg-${bodyColor}`}>{children}</Card.Body>
        </Card>
    );
};

export default InfoCard;
