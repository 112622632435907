/* eslint-disable no-unused-vars */
import config from '../config';
import axios from 'axios';

const { ENV, API_HOST } = config;
const POST = 'post';
const GET = 'get';
const PUT = 'put';
const DELETE = 'delete';

// Remove Console Log for non-dev environment
const host = window.location.host;
console.log =
    host.includes('staging') || host.includes('localhost')
        ? console.log
        : () => {};

if (API_HOST == null) {
    throw new Error('API_HOST not in runtime config!');
}

const axiosInstance = axios.create({
    baseURL: API_HOST,
    headers: { 'Content-Type': 'application/json' },
    timeout: 2500,
});

const createConfig = (method, url, { data = {}, headers = {} } = {}) => {
    return {
        method: method,
        url: url,
        data: data,
        headers: headers,
    };
};

const authHeader = (authToken) => {
    return { Authorization: `Bearer ${localStorage.getItem(authToken)}` };
};

const callAPI = async (config) => {
    console.log('Calling API with:');
    console.log(config);
    try {
        const response = await axiosInstance(config);
        console.log(response);
        return response.data;
    } catch (err) {
        console.log(err.toJSON());
        return null;
    }
};

const controller = (() => ({
    /* ---- CREATE - POST's ---- */
    post_dvid: async () => {
        const url = '/devices';
        const config = createConfig(POST, url);
        const resData = await callAPI(config);

        console.log('From post_dvid:');
        console.log(resData);

        if (resData) {
            localStorage.setItem('dvid', resData.token);
            return true;
        } else {
            return false;
        }
    },
    post_dvid_refresh: async (dvid) => {
        const url = `/devices/${dvid}/token`;
        const config = createConfig(POST, url);
        const resData = await callAPI(config);

        console.log('From post_dvid_refresh:');
        console.log(resData);

        if (resData) {
            localStorage.setItem('dvid', resData.token);
            return true;
        } else {
            return false;
        }
    },
    post_check_in: async (dvid, scannedId) => {
        const url = `/devices/${dvid}/check-ins`;
        const data = { scannedId: scannedId };
        const config = createConfig(POST, url, {
            data: data,
            headers: authHeader('dvid'),
        });
        const resData = await callAPI(config);

        console.log('From post_check_in:');
        console.log(resData);

        return resData?.id;
    },
    post_create_user: async (phoneNum, dvid) => {
        const url = '/users';
        const data = { associatedDevice: dvid, contact: { phone: phoneNum } };
        const config = createConfig(POST, url, {
            data: data,
            headers: authHeader('dvid'),
        });
        const resData = await callAPI(config);

        console.log('From post_create_user:');
        console.log(resData);

        if (resData?.id) {
            localStorage.setItem('pnor', true);
            return true;
        } else {
            return false;
        }
    },
    post_create_site: async (orgId, name) => {
        const url = `/organizations/${orgId}/sites`;
        const data = { category: 'OTHER', subcategory: 'OTHER', name: name };
        const config = createConfig(POST, url, {
            data: data,
            headers: authHeader('token'),
        });
        const resData = await callAPI(config);

        console.log('From post_create_scannable');
        console.log(resData);

        return resData?.id;
    },
    post_create_scannable: async (orgId, siteId, name, type) => {
        const url = `/organizations/${orgId}/sites/${siteId}/scannables`;
        const data = {
            name: name,
            locationCategory: type,
        };
        const config = createConfig(POST, url, {
            data: data,
            headers: authHeader('token'),
        });
        const resData = await callAPI(config);

        console.log('From post_create_scannable');
        console.log(resData);

        return resData?.id;
    },
    post_create_report: async (orgId, reportData) => {
        const url = `/organizations/${orgId}/test-results`;
        const config = createConfig(POST, url, {
            data: reportData,
            headers: authHeader('token'),
        });

        const resData = await callAPI(config);

        console.log('From post_create_report');
        console.log(resData);

        return resData?.id;
    },
    /* ---- READ - GET's ---- */
    get_dvid: () => {
        // TODO: Retrieve Token if exists, Validate Token
        // For now just return (if dvid exists)
        return localStorage.getItem('dvid') !== null;
    },
    get_location_types: async () => {
        const url = '/models/location-types';
        const config = createConfig(GET, url, {
            headers: { 'Accept-Language': 'en' },
        });
        const resData = await callAPI(config);

        console.log('From get_location_types');
        console.log(resData);

        return resData;
    },
    get_orgs: async () => {
        const url = '/organizations';
        const config = createConfig(GET, url, { headers: authHeader('token') });
        const resData = await callAPI(config);

        console.log('From get_org_ids:');
        console.log(resData);

        return resData;
    },
    get_org_scannables: async (orgID) => {
        const url = `/organizations/${orgID}/scannables`;
        const config = createConfig(GET, url, { headers: authHeader('token') });
        const resData = await callAPI(config);

        console.log('From get_org_scannables:');
        console.log(resData);

        return resData;
    },
    get_sites: async (orgID) => {
        const url = `/organizations/${orgID}/sites`;
        const config = createConfig(GET, url, { headers: authHeader('token') });
        const resData = await callAPI(config);

        console.log('From get_site_ids:');
        console.log(resData);

        return resData;
    },
    get_site_scannables: async (orgID, siteID) => {
        const url = `/organizations/${orgID}/sites/${siteID}/scannables`;
        const config = createConfig(GET, url, { headers: authHeader('token') });
        const resData = await callAPI(config);

        console.log('From get_site_scannables:');
        console.log(resData);

        return resData;
    },
    get_phone_numbers: async (number) => {
        const url = `/users?phoneNumber=${number}`;
        const config = createConfig(GET, url, { headers: authHeader('token') });
        const resData = await callAPI(config);

        console.log('From get_users:');
        console.log(resData);

        return resData;
    },
    get_reports: async (orgID, id = '') => {
        const url = `/organizations/${orgID}/test-results/${id}`;
        const config = createConfig(GET, url, { headers: authHeader('token') });

        const resData = await callAPI(config);

        console.log('From get_reports:');
        console.log(resData);

        return resData;
    },
    get_exposure_report: async (orgID, id, asCSV = false) => {
        const url = `/organizations/${orgID}/test-results/${id}/exposure-report`;
        const config = createConfig(GET, url, { headers: authHeader('token') });

        if (asCSV) {
            config.headers['Accept'] = 'text/csv';
        }

        const resData = await callAPI(config);

        console.log('From get_exposure_report:');
        console.log(resData);

        return resData;
    },
    /* ---- UPDATE - PUT's ---- */
    /* ---- DELETE - DELETE's ---- */
    delete_logout: async () => {
        const sessionID = localStorage.getItem('sessionID');
        const url = `/sessions/${sessionID}`;
        const config = createConfig(DELETE, url, {
            headers: authHeader('token'),
        });
        const resData = await callAPI(config);

        console.log('From delete_logout:');
        console.log(resData);

        localStorage.removeItem('token');
        localStorage.removeItem('sessionID');
    },
}))();

export default controller;
