import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const WarningModal = ({
    modalShowing,
    setModalShowing,
    urlOrigin,
    handleRedirect,
}) => {
    const handleClose = (redirect) => {
        setModalShowing(false);
        handleRedirect(redirect);
    };

    return (
        <Modal
            show={modalShowing}
            onHide={() => handleClose(false)}
            animation={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Warning!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                The QR Code that was scanned redirects away from Zerobase! This
                could be an error or a malicious code.
                <br />
                <br />
                QR code redirects to: {urlOrigin}
                <br />
                <br />
                Please notify your on-site Zerobase administrator if you suspect
                an incorrect QR Code.
                <br />
                <br />
                Do you wish to continue?
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="d-inline-block"
                    variant="danger"
                    onClick={() => handleClose(true)}
                >
                    Yes, continue with redirect
                </Button>
                <Button variant="success" onClick={() => handleClose(false)}>
                    NO, stay with Zerobase
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WarningModal;
