/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import jwt_decode from 'jwt-decode';
import PermissionsModal from './modals/PermissionsModal';
import WarningModal from './modals/WarningModal';
import controller from '../../../lib/controller';

/**
 * @description
 *  Renders a QR code scanner screen.
 *  Will request camera permissions on first visit to site.
 *  Displays an invalid permissions modal if user declined camera permissions.
 *  Upon successful QR scan, re-routes to qr-data page
 */
const Scan = () => {
    const history = useHistory();
    const [permissionsShowing, setPermissionsShowing] = useState(false);
    const [warningShowing, setWarningShowing] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('dvid');

        if (token) {
            const data = jwt_decode(token);

            const exp = new Date(data.exp * 1000).valueOf();
            const issued = new Date(data.iat * 1000).valueOf();
            var now = Date.now;

            // Check if less than half time on token remaining
            // If so, refresh token
            if (exp - now < (exp - issued) / 2) {
                console.log('refreshing');
                localStorage.setItem(
                    'dvid',
                    controller.post_dvid_refresh(data.sub),
                );
            }
        }
    }, []);

    const handleData = (data) => {
        const url = new URL(data);
        setData(url);

        const dataHostname = url.hostname;

        if (!dataHostname.includes('zerobase') && url.protocol !== 'https:') {
            setWarningShowing(true);
        } else {
            handleRedirect(true);
        }
    };

    const handleRedirect = (redirect) => {
        if (redirect) {
            history.push(typeof data === 'string' ? data : data?.pathname);
        }
    };

    return (
        <div>
            <QrReader
                delay={500}
                onError={() => setPermissionsShowing(true)}
                onScan={handleData}
            />
            <PermissionsModal
                modalShowing={permissionsShowing}
                setModalShowing={setPermissionsShowing}
            />
            <WarningModal
                modalShowing={warningShowing}
                setModalShowing={setWarningShowing}
                handleRedirect={handleRedirect}
                urlOrigin={data?.origin}
            />
        </div>
    );
};

export default withRouter(Scan);
