/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Plus, Minus, ChevronLeft } from 'react-feather';
import { useHistory, useLocation, withRouter, Link } from 'react-router-dom';
import controller from '../../../lib/controller';

const AdminAddCodes = () => {
    const location = useLocation();
    const history = useHistory();
    const [orgSites, setOrgSites] = useState(null);
    const [orgScannables, setOrgScannables] = useState(null);
    const [locationTypes, setLocationTypes] = useState(null);
    const [locations, setLocations] = useState({
        location1: { name: '', site: '', type: '' },
    });
    const [validInputs, setValidInputs] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const params = new URLSearchParams(location.search);
            const orgId = params.get('org');

            const orgSites = await controller.get_sites(orgId);
            const orgScannables = await controller.get_org_scannables(orgId);
            const locationTypes = await controller.get_location_types();

            setOrgSites(orgSites);
            setOrgScannables(orgScannables);
            setLocationTypes(locationTypes);
        };

        if (!orgSites) {
            getData();
        }

        inputsValid();
    }, [locations]);

    const inputs = () => {
        const locationsAry = Object.keys(locations);
        const types = Object.keys(locationTypes);

        return locationsAry.map((location, idx) => (
            <Form.Row key={idx}>
                <Col>
                    <Form.Group>
                        <Form.Control
                            as="select"
                            style={{
                                appearance: 'auto',
                            }}
                            onChange={handleChange}
                            name={`${location}-site`}
                            required
                            value={locations[location].site}
                        >
                            <option disabled hidden value="">
                                Select Site
                            </option>
                            {orgSites.map((site) => (
                                <option
                                    className="text-black"
                                    key={site.id}
                                    value={site.name}
                                >
                                    {site.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Control
                            name={`${location}-name`}
                            onChange={handleChange}
                            placeholder="Location Name"
                            type="text"
                            value={locations[location].name}
                        />
                        <Form.Text
                            className={idx === 0 ? 'text-muted' : 'd-none'}
                        >
                            Location (room or space) at Site
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Control
                            as="select"
                            style={{ appearance: 'auto' }}
                            onChange={handleChange}
                            name={`${location}-type`}
                            required
                            value={locations[location].type}
                        >
                            <option disabled hidden value="">
                                Select Location Type
                            </option>
                            {types.map((type) => (
                                <option
                                    className="text-black"
                                    key={type}
                                    value={locationTypes[type]}
                                >
                                    {type}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Text
                            className={idx === 0 ? 'text-muted' : 'd-none'}
                        >
                            Large is over 10 people.
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Form.Row>
        ));
    };

    // Validate all fields with every change.
    // If we don't do this then user could type an invalid change in one field,
    // Change another field to a valid input and wind up passing invalid data.
    const inputsValid = () => {
        const inputs = Object.values(locations);
        let valid = true;

        inputs.forEach((entry) => {
            console.log(entry);
            if (!entry.name || !entry.site || !entry.type) {
                valid = false;
            } else {
                const locationName = entry.name.trim().toLowerCase();
                const siteName = entry.site;

                const siteId = orgSites.find((site) => site.name === siteName)
                    .id;

                const location = orgScannables.find(
                    (location) =>
                        location.siteId === siteId &&
                        location.name.trim().toLowerCase() === locationName,
                );

                valid = valid && !location;
            }
        });

        setValidInputs(valid);
    };

    const handleChange = (e) => {
        e.preventDefault();
        const fieldName = e.target.name.split('-');
        const value = e.target.value;

        setLocations({
            ...locations,
            [fieldName[0]]: {
                ...locations[fieldName[0]],
                [fieldName[1]]: value,
            },
        });
    };

    const handleAdd = () => {
        const numLocations = Object.keys(locations).length;
        const newLocations = locations;

        newLocations[`location${numLocations + 1}`] = {
            name: '',
            site: '',
            type: '',
        };

        setLocations(newLocations);
        setValidInputs(false);
    };

    const handleDelete = () => {
        const locationKeys = Object.keys(locations);
        const numLocations = locationKeys.length;
        const newLocations = {};

        locationKeys.forEach((key) => {
            if (key !== `location${numLocations}`) {
                Object.defineProperty(newLocations, key, {
                    value: locations[key],
                    enumerable: true,
                    writable: true,
                });
            }
        });

        console.log(newLocations);

        setLocations(newLocations);
    };

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);
        const params = new URLSearchParams(location.search);
        const orgId = params.get('org');
        const locationValues = Object.values(locations);

        locationValues.map(async (location) => {
            const siteId = orgSites.find((site) => site.name === location.site)
                .id;

            controller.post_create_scannable(
                orgId,
                siteId,
                location.name,
                location.type,
            );
        });

        setSaving(false);
        history.push('/admin/qrcodes');
    };

    return (
        <Container className="text-center">
            <h1 className="bg-dark text-white mt-5 py-4 rounded">
                Add Locations
            </h1>
            <Container>
                <Row>
                    <Col style={{ marginTop: '2em', marginBottom: '2em' }}>
                        <h1>Set up your QR Codes</h1>
                    </Col>
                </Row>
                {orgSites && orgScannables && locationTypes ? (
                    <Container className="my-3 w-75">
                        <Row>
                            <Col>
                                <Form
                                    className="text-left"
                                    onSubmit={handleSave}
                                >
                                    {inputs()}
                                    <Row className="justify-content-end">
                                        <Col xs="auto">
                                            <Button
                                                className="border-0"
                                                hidden={
                                                    Object.keys(locations)
                                                        .length === 1
                                                }
                                                onClick={handleDelete}
                                                variant="white"
                                            >
                                                Remove last Location
                                                <Minus className="ml-2 mr-0" />
                                            </Button>
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                className="border-0"
                                                disabled={!validInputs}
                                                onClick={handleAdd}
                                                variant="white"
                                            >
                                                Add another Location
                                                <Plus className="ml-2 mr-0" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className="mt-5 justify-content-between">
                                        <Col xs="auto">
                                            <Button
                                                as={Link}
                                                to="/admin/qrcodes"
                                                variant="dark"
                                                size="lg"
                                            >
                                                <ChevronLeft />
                                                Back
                                            </Button>
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                disabled={
                                                    !validInputs || saving
                                                }
                                                size="lg"
                                                type="submit"
                                                variant="dark"
                                            >
                                                <Spinner
                                                    animation="border"
                                                    className={
                                                        saving
                                                            ? 'mr-2'
                                                            : 'd-none'
                                                    }
                                                />
                                                {saving ? 'Saving...' : 'Save'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <>
                        <Spinner
                            variant="info"
                            animation="border"
                            role="status"
                        />
                        <span className="ml-2">
                            Loading organization data...
                        </span>
                    </>
                )}
            </Container>
        </Container>
    );
};

export default withRouter(AdminAddCodes);
