import React from 'react';
import { withRouter } from 'react-router-dom';
import { Alert, Image, Modal } from 'react-bootstrap';
import successImg from '../../../../assets/img/info/greenCheck';
import failImg from '../../../../assets/img/info/orangeWarning';
import zb_logo from '../../../../assets/img/branding/zb/zb_logo';

const ResultModal = ({ modalShowing, setModalShowing, success, history }) => {
    const handleClose = () => {
        setModalShowing(false);
        history.replace(success ? '/' : '/scan');
    };

    return (
        <Modal
            show={modalShowing}
            onHide={() => handleClose()}
            backdrop="static"
            keyboard="false"
            animation={false}
            centered
        >
            <Modal.Header className="pr-4 border-0" closeButton>
                <Modal.Title />
            </Modal.Header>
            <Modal.Body>
                <Alert
                    variant={success ? 'success' : 'warning'}
                    className="text-center"
                >
                    Scan{' '}
                    {success
                        ? 'successful. Please Enter.'
                        : 'failed. Please try again'}
                </Alert>
                <Image
                    src={success ? successImg : failImg}
                    alt={success ? 'Success Checkmark' : 'Fail Warning'}
                    className="mx-auto d-block"
                />
            </Modal.Body>
            <Modal.Footer>
                <Image
                    src={zb_logo}
                    alt="Zerobase Logo"
                    className="mx-auto d-block w-50"
                />
            </Modal.Footer>
        </Modal>
    );
};

export default withRouter(ResultModal);
