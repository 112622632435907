import React from 'react';

class Contact extends React.Component {
    render() {
        return (
            <div class="content">
                <div class="container" style={{ padding: '0' }}>
                    <iframe
                        class="airtable-embed airtable-dynamic-height"
                        src="https://airtable.com/embed/shrUJuPNPVpxebXRB?backgroundColor=yellow"
                        frameborder="0"
                        width="100%"
                        height="1149"
                        style={{ background: 'transparent' }}
                        title="Contact"
                    ></iframe>
                </div>
            </div>
        );
    }
}

export default Contact;
