import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
    return (
        <Container>
            <div>
                <h1>Unauthorized Access</h1>
                <p>
                    Please return <Link to="/">home</Link> and login first
                </p>
            </div>
        </Container>
    );
};

export default Unauthorized;
