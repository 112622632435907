import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const PolicyModal = ({ modalShowing, setModalShowing, policy, children }) => {
    return (
        <>
            <Modal
                animation={false}
                show={modalShowing}
                onHide={() => setModalShowing(false)}
                centered
                scrollable
            >
                <Modal.Header className="text-center" closeButton>
                    <Modal.Title>{policy}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShowing(false);
                        }}
                        block
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PolicyModal;
