/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormControl,
    Nav,
    Table,
} from 'react-bootstrap';
import controller from '../../../lib/controller';
import clipboards from 'Images/clipboards.svg';
import { Plus } from 'react-feather';
import InfoCard from '../check-in/components/InfoCard';
import config from '../../../config';
import gPhone from 'google-libphonenumber';
import jwt_decode from 'jwt-decode';

const headerStyle = {
    display: 'inline-block',
    fontSize: '1rem',
    verticalAlign: 'middle',
    lineHeight: '0',
};

const PNF = gPhone.PhoneNumberFormat;
const phoneUtil = gPhone.PhoneNumberUtil.getInstance();

const AdminTests = () => {
    /* const [data, setData] = useState([
        {
            id: '1',
            employee: 'Justin R.',
            exposures: '3',
            testDate: '12/3/2020',
            resultsDate: '12/3/2020',
            employeePhone: '555-1234',
        },
    ]); */
    const [oid, setOID] = useState(null);
    const [data, setData] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        let interval;

        const getData = async () => {
            const orgId = jwt_decode(localStorage.getItem('token')).oid;
            setOID(orgId);

            const orgReports = await controller.get_reports(orgId);

            setData(orgReports);
            interval = setInterval(refreshData, 15000, orgReports, orgId);
        };

        getData();

        return () => clearInterval(interval);
    }, []);

    const refreshData = async (data, id) => {
        const orgReports = await controller.get_reports(id);
        const reports = data?.reduce((data, report) => {
            if (report.testedParty.id && !report.report) {
                const updatedReport = orgReports.find(
                    (r) => r.id === report.id,
                );

                report = updatedReport;
            }

            data.push(report);

            return data;
        }, []);

        setData(reports);
    };

    const rows = () => {
        if (!data?.length) {
            return (
                <tr>
                    <td colSpan="7" className="bg-white border-0 px-10">
                        <InfoCard
                            cardClass="w-50 mx-auto"
                            imageClass="py-3 w-50 mx-auto"
                            image={clipboards}
                            bodyColor="white"
                        >
                            <Card.Title>No tests entered yet!</Card.Title>
                            <Card.Text>
                                There are currently no tests entered for this
                                organization yet. You can report a test by using
                                the "Add Report" button above.
                            </Card.Text>
                        </InfoCard>
                    </td>
                </tr>
            );
        } else {
            const value = searchValue.trim().toLowerCase();
            const rows = data.reduce((data, test) => {
                const testedParty = test.testedParty;
                const employee = testedParty.name.toLowerCase();
                const employeePhone = testedParty.phoneNumber;
                const phoneNoHyphen = employeePhone.replaceAll('-', '');

                if (
                    employee.includes(value) ||
                    employeePhone.includes(value) ||
                    phoneNoHyphen.includes(value)
                ) {
                    const formattedNumber = phoneUtil.parseAndKeepRawInput(
                        employeePhone,
                    );

                    data.push(
                        <tr key={test.id}>
                            <td className="align-middle">{testedParty.name}</td>
                            <td className="align-middle">
                                {!testedParty.id
                                    ? 'No Data'
                                    : !test.estimatedExposures
                                    ? 'Loading...'
                                    : test.estimatedExposures}
                            </td>
                            <td className="align-middle">{test.testDate}</td>
                            <td className="align-middle">{test.resultDate}</td>
                            <td className="align-middle">
                                {phoneUtil.format(
                                    formattedNumber,
                                    PNF.NATIONAL,
                                )}
                            </td>
                            <td className="d-flex align-middle justify-content-around">
                                <Button
                                    as={
                                        !testedParty.id ||
                                        !test.estimatedExposures
                                            ? Button
                                            : Link
                                    }
                                    to={`/admin/tests/report?org=${oid}&id=${test.id}`}
                                    variant="dark"
                                    disabled={
                                        !testedParty.id ||
                                        !test.estimatedExposures
                                    }
                                >
                                    {!testedParty.id
                                        ? 'No Data'
                                        : test.estimatedExposures
                                        ? 'View'
                                        : 'Loading...'}
                                </Button>
                            </td>
                        </tr>,
                    );
                }
                return data;
            }, []);

            return rows;
        }
    };

    return (
        <Container className="text-center">
            <Nav
                className="mt-5 align-items-center justify-content-center"
                variant="pills"
            >
                <Nav.Item className="border border-info mr-1">
                    <Nav.Link
                        className="text-info"
                        as={Link}
                        to="/admin/qrcodes"
                    >
                        Configure QR Codes
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        className="bg-info"
                        active
                        as={Link}
                        to="/admin/tests"
                    >
                        Enter and Manage Reports
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <h1 className="bg-dark text-white mt-5 py-5 rounded">
                Test Result Reports
            </h1>
            <Container className="px-3 pb-3">
                <Form className="mb-2">
                    <Form.Row>
                        <Col
                            className="d-flex"
                            style={{ marginTop: '2em', marginBottom: '1em' }}
                        >
                            <Button
                                as={Link}
                                to={{
                                    pathname: '/admin/tests/addtest',
                                    state: { orgId: oid },
                                }}
                                variant="outline-dark"
                            >
                                Add Report
                                <Plus className="ml-2" />
                            </Button>
                        </Col>
                        <Col style={{ marginTop: '2em', marginBottom: '1em' }}>
                            <FormControl
                                className="mr-2"
                                type="text"
                                placeholder="Search"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </Col>
                    </Form.Row>
                </Form>
                <Table hover responsive>
                    <thead className={!data?.length ? 'd-none' : ''}>
                        <tr>
                            <th className="text-dark">
                                <span style={headerStyle}>Employee</span>
                            </th>
                            <th className="text-dark">
                                <span style={headerStyle}>
                                    Potential Exposures
                                </span>
                            </th>
                            <th className="text-dark">
                                <span style={headerStyle}>Test Date</span>
                            </th>
                            <th className="text-dark">
                                <span style={headerStyle}>Results Date</span>
                            </th>
                            <th className="text-dark">
                                <span style={headerStyle}>
                                    Employee Phone #
                                </span>
                            </th>
                            <th className="text-dark">
                                <span style={headerStyle}>Actions</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>{rows()}</tbody>
                </Table>
            </Container>
        </Container>
    );
};

export default withRouter(AdminTests);
