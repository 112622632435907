/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Plus, ChevronLeft } from 'react-feather';
import { useHistory, useLocation, withRouter, Link } from 'react-router-dom';
import controller from '../../../lib/controller';

const AdminAddSites = () => {
    const location = useLocation();
    const history = useHistory();
    const [orgSites, setOrgSites] = useState(null);
    const [sites, setSites] = useState({ site1: '' });
    const [validInputs, setValidInputs] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const params = new URLSearchParams(location.search);
            const orgId = params.get('org');

            const orgSites = await controller.get_sites(orgId);

            setOrgSites(orgSites);
        };

        getData();
    }, []);

    const inputs = () => {
        const sitesAry = Object.keys(sites);

        return sitesAry.map((site, idx) => (
            <Row className="justify-content-center" key={idx}>
                <Col xs={6}>
                    <Form.Group>
                        <Form.Control
                            name={site}
                            type="text"
                            placeholder="Site name"
                            onChange={handleChange}
                        />
                        <Form.Text
                            className={idx === 0 ? 'text-muted' : 'd-none'}
                        >
                            Building or Facility
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
        ));
    };

    const inputsValid = (fieldName, value) => {
        const siteName = value.trim().toLowerCase();

        if (!siteName.length) {
            setValidInputs(false);
        } else {
            const curValues = Object.entries(sites);
            const isValid =
                !curValues.find((kvp) => {
                    const kvpFieldName = kvp[0];
                    const value = kvp[1];
                    return (
                        kvpFieldName !== fieldName &&
                        value.trim().toLowerCase() === siteName
                    );
                }) &&
                !orgSites?.find(
                    (site) => site.name.trim().toLowerCase() === siteName,
                );

            setValidInputs(isValid);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        const fieldName = e.target.name;
        const value = e.target.value;

        sites[fieldName] = value;

        inputsValid(fieldName, value);
    };

    const handleAdd = () => {
        const numSites = Object.keys(sites).length;
        const newSites = sites;

        newSites[`site${numSites + 1}`] = '';

        setSites(newSites);
        setValidInputs(false);
    };

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);
        const siteNames = Object.values(sites);
        const params = new URLSearchParams(location.search);
        const orgId = params.get('org');

        siteNames.map(async (name) => {
            controller.post_create_site(orgId, name);
        });

        setSaving(false);
        history.push('/admin/qrcodes');
    };

    return (
        <Container className="text-center">
            <h1 className="bg-dark text-white mt-5 py-4 rounded">Add Sites</h1>
            <Container>
                <Row>
                    <Col style={{ marginTop: '2em', marginBottom: '2em' }}>
                        <h1>Set up your Sites</h1>
                    </Col>
                </Row>

                {orgSites ? (
                    <Form className="text-left" onSubmit={handleSave}>
                        {inputs()}
                        <Row className="justify-content-end">
                            <Col xs="auto">
                                <Button
                                    className="border-0"
                                    disabled={!validInputs}
                                    onClick={handleAdd}
                                    variant="white"
                                >
                                    Add another Site
                                    <Plus className="ml-2 mr-0" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mt-5 justify-content-between">
                            <Col xs="auto">
                                <Button
                                    as={Link}
                                    to="/admin/qrcodes"
                                    variant="dark"
                                    size="lg"
                                >
                                    <ChevronLeft />
                                    Back
                                </Button>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    disabled={!validInputs || saving}
                                    size="lg"
                                    type="submit"
                                    variant="dark"
                                >
                                    <Spinner
                                        animation="border"
                                        className={saving ? 'mr-2' : 'd-none'}
                                    />
                                    {saving ? 'Saving...' : 'Save'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <>
                        <Spinner
                            variant="info"
                            animation="border"
                            role="status"
                        />
                        <span className="ml-2">
                            Loading organization data...
                        </span>
                    </>
                )}
            </Container>
        </Container>
    );
};

export default withRouter(AdminAddSites);
