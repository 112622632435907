import React from 'react';
import Login from '../pages/admin/Login';
import { Link } from 'react-router-dom';
import { Menu } from 'react-feather';

const Primary = ({ showAdmin, setRefresh }) => {
    return (
        <nav
            className="navbar navbar-expand-sm navbar-light navbar-secondary-blue navbar-primary py-2"
            id="navbar-primary"
        >
            <div className="container">
                <div className="d-block d-sm-none small">
                    Privacy First Contact Tracing
                </div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <Menu />
                    Menu
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="nav nav-tabs navbar-nav w-100">
                        <li className="nav-item w-md-100">
                            <Link className="nav-link" to="/">
                                Home
                            </Link>
                        </li>
                        <li className="nav-item dropdown w-md-100">
                            {/* eslint-disable-next-line */}
                            <a
                                className="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                                href="#"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Use Cases
                            </a>
                            <div className="dropdown-menu">
                                <Link
                                    className="dropdown-item"
                                    to="/individual"
                                >
                                    Individuals
                                </Link>
                                <Link className="dropdown-item" to="/community">
                                    Community Officials
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/businesses"
                                >
                                    Business or Public Locations
                                </Link>
                                <Link className="dropdown-item" to="/testing">
                                    Healthcare Testing Sites
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item w-md-100">
                            <Link className="nav-link" to="/about">
                                About
                            </Link>
                        </li>
                        <li className="nav-item w-md-100">
                            <Link className="nav-link" to="/privacy">
                                Privacy
                            </Link>
                        </li>
                        <li className="nav-item w-md-100">
                            <Link className="nav-link" to="/team">
                                Team
                            </Link>
                        </li>
                        <li className="nav-item w-md-100">
                            <Link className="nav-link" to="/contact">
                                Contact Us
                            </Link>
                        </li>
                        <li
                            className={
                                showAdmin
                                    ? 'nav-item w-md-100 ml-auto'
                                    : 'd-none'
                            }
                        >
                            <Link className="nav-link" to="/admin">
                                Admin Home
                            </Link>
                        </li>
                        <Login className="d-md-none" setRefresh={setRefresh} />
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Primary;
