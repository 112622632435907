/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

import jwt_decode from 'jwt-decode';

import Primary from './components/navigation/Primary';
import Secondary from './components/navigation/Secondary';
import Footer from './components/navigation/Footer';

import AboutUsPage from './components/pages/AboutUs';
import AdminPage from './components/pages/admin/AdminPortal';
import AdminCodesPage from './components/pages/admin/AdminCodes';
import AdminAddSitesPage from './components/pages/admin/AdminAddSites';
import AdminAddCodesPage from './components/pages/admin/AdminAddCodes';
import AdminTestsPage from './components/pages/admin/AdminTests';
import AdminAddTestPage from './components/pages/admin/AdminAddTest';
import AdminDashboardPage from './components/pages/admin/AdminDashboard';
import AdminViewReportPage from './components/pages/admin/AdminViewReport';
import BusinessLandingPage from './components/pages/BusinessLanding';
//import BusinessRegisterPage from './components/pages/BusinessRegister';
import CheckInPage from './components/pages/check-in/CheckIn';
import CommunityPage from './components/pages/Community';
import ContactPage from './components/pages/Contact';
import FeedbackPage from './components/pages/Feedback';
//import HealthCareRegisterPage from './components/pages/HealthCareRegister';
import HomePage from './components/pages/Home';
import IndividualsLandingPage from './components/pages/IndividualsLanding';
import NotFoundPage from './components/views/errors/NotFound';
//import NotificationsPage from './components/pages/Notifications';
import OurTeamPage from './components/pages/OurTeam';
import PrivacyPage from './components/pages/Privacy';
import PrivacyLandingPage from './components/pages/PrivacyLanding';
import ScanPage from './components/pages/check-in/Scan';
import TermsPage from './components/pages/Terms';
import TestingsiteLandingPage from './components/pages/TestingsiteLanding';
import UnauthorizedPage from './components/views/errors/Unauthorized';
import VolunteerLandingPage from './components/pages/VolunteerLanding';

const App = () => {
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }
    }, [refresh]);

    const protectedRoutes = () => {
        // Check if a token exists
        const isAdminUser = () => {
            const token = localStorage.getItem('token');
            if (token) {
                // Verify expiration and role
                const data = jwt_decode(token);
                const exp = data.exp;
                const role = data.role;

                if (exp < Date.now() / 1000) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('sessionID');
                    return false;
                }

                return role === 'zerobase' || role === 'admin';
            } else {
                return false;
            }
        };

        const protectedRoutes = isAdminUser()
            ? [
                  <Route
                      key="admin"
                      exact
                      path="/admin"
                      component={AdminPage}
                  />,
                  <Route
                      key="admincodes"
                      exact
                      path="/admin/qrcodes"
                      component={AdminCodesPage}
                  />,
                  <Route
                      key="adminaddsites"
                      exact
                      path="/admin/qrcodes/addsites"
                      component={AdminAddSitesPage}
                  />,
                  <Route
                      key="adminaddcodes"
                      exact
                      path="/admin/qrcodes/addcodes"
                      component={AdminAddCodesPage}
                  />,
                  <Route
                      key="admintests"
                      exact
                      path="/admin/tests"
                      component={AdminTestsPage}
                  />,
                  <Route
                      key="adminaddtest"
                      exact
                      path="/admin/tests/addtest"
                      component={AdminAddTestPage}
                  />,
                  <Route
                      key="adminviewreport"
                      exact
                      path="/admin/tests/report"
                      component={AdminViewReportPage}
                  />,
                  <Route
                      key="admindashboard"
                      exact
                      path="/admin/dashboard"
                      component={AdminDashboardPage}
                  />,
                  <Route
                      key="unauthorized"
                      exact
                      path="/unauthorized"
                      component={UnauthorizedPage}
                  />,
              ]
            : [];

        return protectedRoutes;
    };

    return (
        <>
            <div id="app">
                <div style={{ overflow: 'hidden' }}>
                    <Router>
                        <Secondary setRefresh={setRefresh} />
                        <Primary
                            setRefresh={setRefresh}
                            showAdmin={protectedRoutes().length}
                        />
                        <Switch>
                            {protectedRoutes()}
                            <Route path="/about">
                                <AboutUsPage />
                            </Route>
                            <Route path="/businesses">
                                <BusinessLandingPage />
                            </Route>
                            {/* <Route path="/business/register">
                                <BusinessRegisterPage />
                            </Route> */}
                            <Route path="/s/:sdvid">
                                {isBrowser ? (
                                    <Redirect to="/" />
                                ) : (
                                    <CheckInPage />
                                )}
                            </Route>
                            <Route path="/community">
                                <CommunityPage />
                            </Route>
                            <Route path="/contact">
                                <ContactPage />
                            </Route>
                            <Route path="/feedback">
                                <FeedbackPage />
                            </Route>
                            {/* <Route path="/healthcare/register">
                                <HealthCareRegisterPage />
                            </Route> */}
                            <Route exact path="/">
                                <HomePage />
                            </Route>
                            <Route path="/individual">
                                <IndividualsLandingPage />
                            </Route>
                            {/* <Route path="/notifications">
                                <NotificationsPage />
                            </Route> */}
                            <Route path="/team">
                                <OurTeamPage />
                            </Route>
                            <Route path="/privacy-policy">
                                <PrivacyPage />
                            </Route>
                            <Route path="/privacy">
                                <PrivacyLandingPage />
                            </Route>
                            <Route path="/scan">
                                <ScanPage />
                            </Route>
                            <Route path="/terms">
                                <TermsPage />
                            </Route>
                            <Route path="/testing">
                                <TestingsiteLandingPage />
                            </Route>
                            <Route path="/volunteer">
                                <VolunteerLandingPage />
                            </Route>
                            <Route component={NotFoundPage} />
                        </Switch>
                    </Router>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default App;
