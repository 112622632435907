/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormControl,
    Nav,
    OverlayTrigger,
    Row,
    Spinner,
    Table,
    Tooltip,
} from 'react-bootstrap';
import controller from '../../../lib/controller';
import clipboards from 'Images/clipboards.svg';
import InfoCard from '../check-in/components/InfoCard';
import TableActions from './components/TableActions';
import { Plus, Info } from 'react-feather';
import config from '../../../config';
import jwt_decode from 'jwt-decode';

const { API_HOST } = config;
const headerStyle = {
    display: 'inline-block',
    fontSize: '1rem',
    verticalAlign: 'middle',
    lineHeight: '0',
};

const AdminCodes = () => {
    const [oid, setOID] = useState(null);
    const [data, setData] = useState(null);
    const [hasSites, setHasSites] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    let interval;

    useEffect(() => {
        const getData = async () => {
            const orgId = jwt_decode(localStorage.getItem('token')).oid;
            setOID(orgId);
            const orgSites = await controller.get_sites(orgId);
            const orgScannables = await controller.get_org_scannables(orgId);
            const locationTypes = await controller.get_location_types();

            // Need to invert as API returns KVP as HumanReadable: Data Value
            const typesInvertedKVP = {};

            Object.entries(locationTypes).forEach((kvp) => {
                Object.defineProperty(typesInvertedKVP, kvp[1], {
                    value: kvp[0],
                    enumerable: true,
                });
            });

            let sites = {};
            if (orgSites?.length) {
                orgSites.forEach((site) => {
                    sites[site.id] = site.name;
                });
                setHasSites(true);
            }

            const scannables = orgScannables?.reduce((data, scannable) => {
                const item = {
                    siteId: scannable.siteId,
                    siteName: sites[scannable.siteId],
                    scannableId: scannable.id,
                    scannableName: scannable.name,
                    scannableType: !typesInvertedKVP[scannable.locationCategory]
                        ? 'Not set'
                        : typesInvertedKVP[scannable.locationCategory],
                    timestamp: scannable.lastInteraction
                        ? new Date(scannable.lastInteraction).toLocaleString()
                        : 'No check-ins',
                    downloadable: scannable.downloadAvailable,
                };

                data.push(item);

                return data;
            }, []);

            setData(scannables);

            interval = setInterval(refreshData, 15000, scannables, orgId);
        };

        getData();

        return () => clearInterval(interval);
    }, []);

    const refreshData = async (data, id) => {
        const orgScannables = await controller.get_org_scannables(id);
        const scannables = data?.reduce((data, scannable) => {
            if (!scannable.downloadAvailable) {
                const updatedScannable = orgScannables.find(
                    (s) => s.id === scannable.scannableId,
                );

                scannable.downloadable = updatedScannable.downloadAvailable;
            }

            data.push(scannable);

            return data;
        }, []);
        setData(scannables);
    };

    const rows = () => {
        if (!data?.length) {
            return (
                <tr>
                    <td colSpan="4" className="bg-white border-0 px-10">
                        <InfoCard
                            cardClass="w-50 mx-auto"
                            imageClass="py-3 w-50 mx-auto"
                            image={clipboards}
                            bodyColor="white"
                        >
                            <Card.Title>
                                No locations registered yet!
                            </Card.Title>
                            <Card.Text>
                                There are currently no locations registered for
                                any sites in this organization yet. You can add
                                locations by using the "Add Locations" button
                                above.
                            </Card.Text>
                        </InfoCard>
                    </td>
                </tr>
            );
        } else {
            const value = searchValue.trim().toLowerCase();
            const rows = data.reduce((data, scannable) => {
                const siteName = scannable.siteName.toLowerCase();
                const scannableName = scannable.scannableName.toLowerCase();

                if (siteName.includes(value) || scannableName.includes(value)) {
                    data.push(
                        <tr key={scannable.scannableId}>
                            <td className="align-middle">
                                {scannable.siteName}
                            </td>
                            <td className="align-middle">
                                {scannable.scannableName}
                            </td>
                            <td
                                className={`align-middle ${
                                    scannable.scannableType === 'Not set'
                                        ? 'font-italic'
                                        : ''
                                }`}
                            >
                                {scannable.scannableType}
                            </td>
                            <td
                                className={`align-middle ${
                                    scannable.timestamp === 'No check-ins'
                                        ? 'font-italic'
                                        : ''
                                }`}
                            >
                                {scannable.timestamp}
                            </td>
                            <TableActions
                                apiURL={`${API_HOST}/organizations/${oid}/sites/${scannable.siteId}/scannables/${scannable.scannableId}/pdf`}
                                downloadable={scannable.downloadable}
                            />
                        </tr>,
                    );
                }
                return data;
            }, []);

            return rows;
        }
    };

    return (
        <Container className="text-center">
            <Nav
                className="mt-5 align-items-center justify-content-center"
                variant="pills"
            >
                <Nav.Item className="mr-1">
                    <Nav.Link
                        className="bg-info"
                        active
                        as={Link}
                        to="/admin/qrcodes"
                    >
                        Configure QR Codes
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item className="border border-info">
                    <Nav.Link className="text-info" as={Link} to="/admin/tests">
                        Enter and Manage Reports
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <h1 className="bg-dark text-white mt-5 py-5 rounded">QR Codes</h1>
            <Container
                className="px-3 pb-3"
                style={{
                    marginTop: '2em',
                }}
            >
                <Row
                    className="align-items-center"
                    style={{
                        marginBottom: '1em',
                    }}
                >
                    <Col xs="auto">
                        <Button
                            as={Link}
                            to={`/admin/qrcodes/addsites?org=${oid}`}
                            variant="outline-dark"
                        >
                            Add Sites
                            <Plus className="ml-2 mr-0" />
                        </Button>
                    </Col>
                    {hasSites ? (
                        <Col xs="auto">
                            <Button
                                as={Link}
                                to={`/admin/qrcodes/addcodes?org=${oid}`}
                                variant="outline-dark"
                            >
                                Add Locations
                                <Plus className="ml-2 mr-0" />
                            </Button>
                        </Col>
                    ) : null}
                    <Col xs={6} className="ml-auto">
                        <Form className="mb-2">
                            <Form.Row>
                                <Col>
                                    <FormControl
                                        className="mr-2"
                                        type="text"
                                        placeholder="Search by Site or Location name"
                                        value={searchValue}
                                        onChange={(e) =>
                                            setSearchValue(e.target.value)
                                        }
                                    />
                                </Col>
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {data ? (
                            hasSites ? (
                                <Table hover responsive>
                                    <thead
                                        className={
                                            !data?.length ? 'd-none' : ''
                                        }
                                    >
                                        <tr>
                                            <th className="text-dark">
                                                <span style={headerStyle}>
                                                    Site
                                                </span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    transition={false}
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            Building or Facility
                                                        </Tooltip>
                                                    }
                                                >
                                                    {({
                                                        ...triggerHandler
                                                    }) => (
                                                        <Info
                                                            size="1rem"
                                                            className="ml-1"
                                                            {...triggerHandler}
                                                        />
                                                    )}
                                                </OverlayTrigger>
                                            </th>
                                            <th className="text-dark align-items-center">
                                                <span style={headerStyle}>
                                                    Location
                                                </span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    transition={false}
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            Room or Space within
                                                            Site
                                                        </Tooltip>
                                                    }
                                                >
                                                    {({
                                                        ...triggerHandler
                                                    }) => (
                                                        <Info
                                                            className="ml-1"
                                                            size="1rem"
                                                            {...triggerHandler}
                                                        />
                                                    )}
                                                </OverlayTrigger>
                                            </th>
                                            <th className="text-dark align-items-center">
                                                <span style={headerStyle}>
                                                    Location Type
                                                </span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    transition={false}
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            Type or capacity of
                                                            the space.
                                                        </Tooltip>
                                                    }
                                                >
                                                    {({
                                                        ...triggerHandler
                                                    }) => (
                                                        <Info
                                                            className="ml-1"
                                                            size="1rem"
                                                            {...triggerHandler}
                                                        />
                                                    )}
                                                </OverlayTrigger>
                                            </th>
                                            <th className="text-dark">
                                                <span style={headerStyle}>
                                                    Last Check-In
                                                </span>
                                            </th>
                                            <th className="text-dark">
                                                <span style={headerStyle}>
                                                    Actions
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{rows()}</tbody>
                                </Table>
                            ) : (
                                <InfoCard
                                    cardClass="w-50 mx-auto"
                                    imageClass="py-3 w-50 mx-auto"
                                    image={clipboards}
                                    bodyColor="white"
                                >
                                    <Card.Title>
                                        No sites registered yet!
                                    </Card.Title>
                                    <Card.Text>
                                        There are currently no sites registered
                                        for this organization yet. You can add
                                        sites by using the "Add Sites" button
                                        above.
                                    </Card.Text>
                                </InfoCard>
                            )
                        ) : (
                            <>
                                <Spinner
                                    variant="info"
                                    animation="border"
                                    role="status"
                                />
                                <span className="ml-2">Loading...</span>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default withRouter(AdminCodes);
