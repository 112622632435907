/* eslint-disable no-unused-vars*/
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const TableActions = ({ apiURL, downloadable }) => {
    return (
        <td className="d-flex align-middle justify-content-around">
            <Button
                href={apiURL}
                target="_blank"
                disabled={!downloadable}
                variant="dark"
            >
                {downloadable ? (
                    'Preview'
                ) : (
                    <>
                        <Spinner
                            className="mx-auto d-block"
                            animation="border"
                            variant="info"
                        />
                        <span className="ml-2">Loading...</span>
                    </>
                )}
            </Button>
        </td>
    );
};

export default TableActions;
