import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Navbar } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import { Maximize } from 'react-feather';

import Login from '../pages/admin/Login';
import zb_logo from 'Images/ZerobaseLogo_Dark.png';

// TODO: Add company logo to bar when user admin is logged in
const Secondary = ({ setRefresh }) => {
    return (
        <Container fluid className="p-0">
            <BrowserView>
                <Navbar bg="dark">
                    <Container>
                        <Navbar.Brand to="/" as={Link}>
                            <img
                                src={zb_logo}
                                alt="Zerobase Logo"
                                width="125"
                            />
                        </Navbar.Brand>
                        <Navbar.Text className="text-white">
                            Privacy-First Contact Tracing for Communities
                        </Navbar.Text>
                        <Login setRefresh={setRefresh} />
                    </Container>
                </Navbar>
            </BrowserView>
            <MobileView>
                <Navbar bg="dark">
                    <Navbar.Brand href="/">
                        <img src={zb_logo} alt="Zerobase Logo" width="125" />
                        <h6 className="m-0 text-white">
                            Privacy-First Contact Tracing for Communities
                        </h6>
                    </Navbar.Brand>
                    <Button
                        id="scan-button"
                        as={Link}
                        to="/scan"
                        variant="light"
                        className="ml-auto mr-2"
                    >
                        <Maximize />
                        Scan
                    </Button>
                </Navbar>
            </MobileView>
        </Container>
    );
};

export default Secondary;
