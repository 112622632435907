import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup, Card, Form, ToggleButton } from 'react-bootstrap';
import controller from '../../../lib/controller';
import jwt_decode from 'jwt-decode';

import Terms from '../Terms';
import Privacy from '../Privacy';
import PolicyModal from './modals/PolicyModal';
import PhoneNumberField from '../../components/Form/PhoneNumberField';
import OrganizationCard from './components/OrganizationCard';
import StatusCard from './components/StatusCard';
import InfoCard from './components/InfoCard';
import ResultModal from './modals/ResultModal';
import { Check, Square } from 'react-feather';
import placeholder from 'Images/info/business';

const CheckIn = ({ match }) => {
    const numField = useRef(null);
    const [regDevice, setRegDevice] = useState(false);
    const [card, setCard] = useState(1);
    const [status, setStatus] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [policyTitle, setPolicyTitle] = useState('');
    const [policy, setPolicy] = useState(null);
    const [viewPolicy, setViewPolicy] = useState(false);
    const [acceptedLegal, setAcceptedLegal] = useState(false);
    const [phoneIsValid, setPhoneIsValid] = useState(false);
    const [phoneNum, setPhoneNumber] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const isRegistered = async () => {
            if (controller.get_dvid()) {
                // Does user have phone number on record (pnor)?
                // This could be checked for mandatory entry later with
                // an organization query
                setShowSpinner(true);
                setStatus('Connecting...');
                const pnor = localStorage.getItem('pnor');
                if (pnor && pnor === 'true') {
                    // POST check-in
                    handleCheckIn();
                } else {
                    // Ask to fill in phone?
                    setStatus('Something went wrong.');
                    setShowSpinner(false);
                    setShowResult(true);
                }

                // Else ask user if they would like to enter a phone number?
            } else {
                // Register device and phone number (TODO: is mandated by company?)
                setShowSpinner(false);
                setStatus('Please register your device');
                setRegDevice(true);
            }
        };

        setShowSpinner(true);
        setStatus('Checking if device is registered...');
        // Validation:
        //  Is user registered? (Has valid dvid)
        isRegistered();

        // eslint-disable-next-line
    }, []);

    const handleCheckIn = async () => {
        const token = localStorage.getItem('dvid');
        const data = jwt_decode(token);
        const dvid = data.sub;

        const result = await controller.post_check_in(dvid, match.params.sdvid);
        // TODO figure out correct auth process
        if (result) {
            setStatus('Success!');
            setSuccess(true);
        } else {
            setStatus('Something went wrong.');
        }

        setShowSpinner(false);
        setShowResult(true);
    };

    const handleRegistration = async () => {
        setCard(1);
        setShowSpinner(true);
        setStatus('Registering Device...');

        if (await controller.post_dvid()) {
            const token = localStorage.getItem('dvid');
            const data = jwt_decode(token);
            const dvid = data.sub;

            if (await controller.post_create_user(phoneNum, dvid)) {
                handleCheckIn();
            } else {
                setStatus('Something went wrong.');
            }
        } else {
            setStatus('Something went wrong.');
        }

        setShowSpinner(false);
        setRegDevice(false);
        setShowResult(true);
    };

    return (
        <div>
            <OrganizationCard>
                <StatusCard
                    cardClass={card === 1 ? '' : 'd-none'}
                    statusText={status}
                    showSpinner={showSpinner}
                    imageClass="w-100 mx_auto"
                    image={placeholder}
                    bodyColor="white"
                />
                <InfoCard
                    cardClass={card === 2 ? '' : 'd-none'}
                    image={placeholder}
                    imageClass="w-100 mx-auto"
                    bodyColor="white"
                >
                    <Card.Text>
                        Please review and accept our{' '}
                        {/* eslint-disable-next-line */}
                        <a
                            className="text-blue"
                            onClick={() => {
                                setPolicyTitle('Terms of Use');
                                setPolicy(<Terms />);
                                setViewPolicy(true);
                            }}
                        >
                            Terms of Use
                        </a>{' '}
                        and {/* eslint-disable-next-line */}
                        <a
                            className="text-blue"
                            onClick={() => {
                                setPolicyTitle('Privacy Policy');
                                setPolicy(<Privacy />);
                                setViewPolicy(true);
                            }}
                        >
                            Privacy Policy
                        </a>
                    </Card.Text>
                    <ButtonGroup toggle className="mb-2">
                        <ToggleButton
                            variant="outline-dark"
                            type="checkbox"
                            checked={acceptedLegal}
                            onChange={(e) =>
                                setAcceptedLegal(e.currentTarget.checked)
                            }
                        >
                            {acceptedLegal ? (
                                <Check className="mr-2" />
                            ) : (
                                <Square className="mr-2" />
                            )}
                            I accept the <br />
                            Terms of Use and Privacy Policy
                        </ToggleButton>
                    </ButtonGroup>
                </InfoCard>
                <InfoCard
                    cardClass={card === 3 ? '' : 'd-none'}
                    image={placeholder}
                    imageClass="w-100 mx-auto"
                >
                    <Card.Text>
                        Please enter your phone number so that you can be
                        notified in case of exposure to COVID-19.
                    </Card.Text>
                    <Form>
                        <Form.Group controlId="formPhoneNumber">
                            <Form.Label>Phone:</Form.Label>
                            <PhoneNumberField
                                ref={numField}
                                name="phone"
                                required={true}
                                onChange={(phoneNum) => {
                                    if (!phoneNum) {
                                        setPhoneNumber(null);
                                    }
                                    setPhoneNumber(phoneNum);
                                }}
                                isValid={setPhoneIsValid}
                            />
                            <h6>Select country, then enter number</h6>
                        </Form.Group>
                    </Form>
                </InfoCard>
                <Button
                    className={regDevice ? '' : 'd-none'}
                    disabled={
                        (card === 2 && !acceptedLegal) ||
                        (card === 3 && !phoneIsValid)
                    }
                    onClick={() =>
                        card === 3 ? handleRegistration() : setCard(card + 1)
                    }
                >
                    {card !== 3 ? 'Next' : 'Submit'}
                </Button>
            </OrganizationCard>
            <PolicyModal
                modalShowing={viewPolicy}
                setModalShowing={setViewPolicy}
                policy={policyTitle}
                children={policy}
            />
            <ResultModal
                modalShowing={showResult}
                setModalShowing={setShowResult}
                success={success}
            />
        </div>
    );
};

export default withRouter(CheckIn);
