import React, { useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const PhoneNumberField = React.forwardRef(
    ({ required, onChange, name, country, isValid }, ref) => {
        const [className, setClassname] = useState('form-control');
        return (
            <IntlTelInput
                preferredCountries={['us', 'cr', 'br']}
                defaultCountry={country}
                inputClassName={className}
                style={{ width: '100%' }}
                onPhoneNumberChange={(valid, phone, country) => {
                    setClassname('form-contol');
                    onChange(phone);
                    isValid(valid, phone);
                }}
                onPhoneNumberBlur={() => {
                    const input = ref.current;
                    const formatted = input.formatFullNumber(input.tel.value);
                    const newValue = formatted.split(' ').join('');
                    input.updateValFromNumber(newValue);
                    onChange(newValue);
                }}
                fieldName={name}
                telInputProps={{
                    id: 'business-phone',
                    required: required ? 'required' : null,
                    onInvalid: () => {
                        setClassname('form-control is-invalid');
                    },
                    onSubmit: () => {
                        setClassname('form-control is-valid');
                    },
                }}
                ref={ref}
            />
        );
    },
);

export default PhoneNumberField;
