/* eslint-disable react-hooks/exhaustive-deps*/
/* eslint-disable no-unused-vars*/
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import config from '../../../config';
import controller from '../../../lib/controller';

const Login = ({ setRefresh, className }) => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        // Was this component rendered after OAuth redirect?
        const params = new URLSearchParams(location.search);
        if (params.has('token')) {
            const token = params.get('token');
            const decode = jwt_decode(token);
            const sessionID = decode.jti;

            const maxAge = Math.abs(
                Math.round((decode.exp * 1000 - Date.now()) / 1000),
            );
            const expiration = new Date(decode.exp * 1000).toUTCString();

            let domain = window.location.hostname;
            if (domain.startsWith('www.')) {
                domain = domain.substring(4);
            }

            let cookieString = `token=${token};domain=${domain};max-age=${maxAge};expires=${expiration};samesite=lax;`;
            if (process.env.NODE_ENV === 'production') {
                cookieString = `${cookieString};secure`;
            }
            document.cookie = cookieString;

            localStorage.setItem('token', token);
            localStorage.setItem('sessionID', sessionID);
            history.push('/admin');
            setRefresh(true);
        }
    }, []);

    // Using handleLogin vs href on button so we don't always have to reset href
    const handleLogin = () => {
        const origin = window.location.origin;

        // This will redirect user to API OAuth login.
        // Need to extract token and remove from URL on next render
        window.location.href = `${config.API_HOST}/oauth?redirect=${origin}`;
    };

    const handleLogout = async () => {
        await controller.delete_logout();
        document.cookie = `token=;max-age=${-1}`;
        const redirect =
            location.pathname.indexOf('/admin') >= 0 ? '/' : location.pathname;
        history.push(redirect);
        setRefresh(true);
    };

    const authButton = () => {
        const token = localStorage.getItem('token');
        let isAdminUser = false;

        if (token) {
            const data = jwt_decode(token);
            const role = data.role.toLowerCase();
            isAdminUser = role === 'zerobase' || role === 'admin';
        }

        return (
            <Button
                className={className}
                variant="light"
                onClick={!isAdminUser ? handleLogin : handleLogout}
            >
                {isAdminUser ? 'Sign Out' : 'Sign In'}
            </Button>
        );
    };

    return authButton();
};

export default Login;
