/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap';
import PhoneNumberField from '../../components/Form/PhoneNumberField';
import { useHistory, useLocation } from 'react-router-dom';
import controller from '../../../lib/controller';

const AdminAddTest = () => {
    const history = useHistory();
    const location = useLocation();
    const numberField = useRef();
    const [name, setName] = useState('');
    const [number, setNumber] = useState(null);
    const [autoCompleteNumbers, setAutoCompleteNumbers] = useState(null);
    const [validNumber, setValidNumber] = useState(true);
    const [testDate, setTestDate] = useState('');
    const [resultDate, setResultDate] = useState('');
    const [noSymptoms, setNoSymptoms] = useState(false);
    const [noMemory, setNoMemory] = useState(false);
    const [symptoms, setSymptoms] = useState(false);
    const [symptomsDate, setSymptomsDate] = useState('');
    const [validated, setValidated] = useState(false);

    const handleSave = async (event) => {
        event.preventDefault();
        const orgId = location.state.orgId;
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
        } else {
            const curDialCode =
                numberField.current.selectedCountryData.dialCode;

            const modNumber =
                number[0] === '+'
                    ? number.slice(curDialCode.length + 1).replace('-', '')
                    : number.replace('-', '');

            const user = modNumber?.replace('-', '')
                ? await controller.get_phone_numbers(curDialCode + modNumber)
                : [];

            const data = {
                party: {
                    id: user?.length ? user[0].id : null,
                    name: name,
                    phoneNumber: number.replace('-', ''),
                },
                testDate: testDate,
                resultDate: resultDate,
                symptomOnset: noSymptoms
                    ? 'NEVER'
                    : noMemory
                    ? 'UNKNOWN'
                    : symptomsDate,
            };

            await controller.post_create_report(orgId, data);
            history.push('/admin/tests');
        }

        setValidated(true);
    };

    const isValidNumber = (valid, number) => {
        console.log(number.replace('-', ''));
        setValidNumber(valid);
    };

    const handleNumberChange = async (number) => {
        const curDialCode = numberField.current.selectedCountryData.dialCode;

        const modNumber =
            number[0] === '+'
                ? number.slice(curDialCode.length + 1).replace('-', '')
                : number.replace('-', '');

        const users = modNumber?.replace('-', '')
            ? await controller.get_phone_numbers(
                  curDialCode + modNumber.replace('-', ''),
              )
            : [];

        const numbers = users?.length
            ? users.map((user) =>
                  user.phoneNumber.slice(1 + curDialCode.length),
              )
            : null;

        setAutoCompleteNumbers(numbers);
        setNumber(number.replace('-', ''));
    };

    const handleNumberSelect = (number) => {
        const input = numberField.current;
        const formatted = input.formatFullNumber(number);
        const newValue = formatted.split(' ').join('');
        input.updateValFromNumber(newValue, false, true);
        setNumber(newValue);
    };

    return (
        <Container className="text-center">
            <Row>
                <Col>
                    <h1 className="bg-dark text-white my-5 py-5 rounded">
                        Add New Test Result
                    </h1>
                </Col>
            </Row>
            <Row className="px-3 text-left">
                <Col>
                    <Form noValidate onSubmit={handleSave}>
                        <Row>
                            <Col xs={4}>
                                <Form.Group controlId="reportName">
                                    <Form.Label>
                                        Name of Tested Individual
                                    </Form.Label>
                                    <Form.Control
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        isValid={validated && name}
                                        isInvalid={validated && !name}
                                        required
                                        type="text"
                                        placeholder="Enter Name"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        You must enter the name of the tested
                                        individual associated with this report.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Form.Group controlId="reportNumber">
                                    <Form.Label>
                                        Phone Number of Tested Individual
                                    </Form.Label>

                                    <Dropdown drop="right">
                                        <Dropdown.Toggle
                                            variant="white"
                                            className="p-0"
                                        >
                                            <PhoneNumberField
                                                ref={numberField}
                                                name="phone"
                                                required
                                                onChange={handleNumberChange}
                                                isValid={isValidNumber}
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            show={
                                                autoCompleteNumbers?.length > 0
                                            }
                                        >
                                            {autoCompleteNumbers?.map(
                                                (number) => (
                                                    <Dropdown.Item
                                                        key={number}
                                                        onSelect={() => {
                                                            handleNumberSelect(
                                                                number.replace(
                                                                    '-',
                                                                    '',
                                                                ),
                                                            );
                                                        }}
                                                    >
                                                        {number}
                                                    </Dropdown.Item>
                                                ),
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Form.Text
                                        className={
                                            !validated
                                                ? ''
                                                : number
                                                ? validNumber &&
                                                  autoCompleteNumbers?.length ===
                                                      1
                                                    ? 'd-block text-success'
                                                    : 'd-block text-warning'
                                                : 'd-block text-danger'
                                        }
                                    >
                                        {!validated
                                            ? 'First, select country, then enter number'
                                            : number
                                            ? validNumber &&
                                              autoCompleteNumbers?.length === 1
                                                ? ''
                                                : 'This phone number does not belong to a registered device. Contact tracing will not be possible until this phone number is registered to a device'
                                            : 'You must enter a phone number for the tested individual.'}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Form.Group controlId="reportTestDate">
                                    <Form.Label>Date of Test</Form.Label>
                                    <Form.Control
                                        required
                                        isValid={testDate}
                                        isInvalid={
                                            validated &&
                                            (!testDate ||
                                                new Date(testDate) >
                                                    new Date(resultDate))
                                        }
                                        type="date"
                                        max={new Date()
                                            .toISOString()
                                            .substr(0, 10)}
                                        value={testDate}
                                        onChange={(e) => {
                                            setTestDate(e.target.value);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        A date for the test must be entered and
                                        be on or before the date of the received
                                        results.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Form.Group controlId="reportResultDate">
                                    <Form.Label>
                                        Date Test Results Returned
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        isValid={
                                            validated &&
                                            resultDate &&
                                            new Date(resultDate) >
                                                new Date(testDate)
                                        }
                                        isInvalid={
                                            validated &&
                                            (!resultDate ||
                                                new Date(resultDate) <
                                                    new Date(testDate))
                                        }
                                        type="date"
                                        min={testDate}
                                        max={new Date()
                                            .toISOString()
                                            .substr(0, 10)}
                                        value={resultDate}
                                        onChange={(e) => {
                                            setResultDate(e.target.value);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        A date that the test results were
                                        returned must be entered and be on or
                                        after the date of the test.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="auto">
                                <Form.Group controlId="reportSymptoms">
                                    <Form.Label>Symptoms Noticed?</Form.Label>

                                    <Form.Check
                                        required={!symptoms && !noMemory}
                                        isValid={noSymptoms}
                                        isInvalid={
                                            validated &&
                                            !noSymptoms &&
                                            !noMemory &&
                                            !symptoms
                                        }
                                        type="radio"
                                        label="No symptoms so far"
                                        id="NoSymptoms"
                                        name="symptoms"
                                        onClick={() => {
                                            setNoSymptoms(true);
                                            setNoMemory(false);
                                            setSymptoms(false);
                                        }}
                                    />
                                    <Form.Check
                                        required={!symptoms && !noSymptoms}
                                        isValid={noMemory}
                                        isInvalid={
                                            validated &&
                                            !noSymptoms &&
                                            !noMemory &&
                                            !symptoms
                                        }
                                        type="radio"
                                        label="Can't remember when symptoms began"
                                        id="NoMemory"
                                        name="symptoms"
                                        onClick={() => {
                                            setNoSymptoms(false);
                                            setNoMemory(true);
                                            setSymptoms(false);
                                        }}
                                    />
                                    <Form.Check>
                                        <Form.Check.Input
                                            required={
                                                (!noSymptoms && !noMemory) ||
                                                symptomsDate
                                            }
                                            isValid={symptoms && symptomsDate}
                                            isInvalid={
                                                validated &&
                                                ((!noSymptoms &&
                                                    !noMemory &&
                                                    !symptoms) ||
                                                    (symptoms && !symptomsDate))
                                            }
                                            type="radio"
                                            id="SymptomsDate"
                                            onClick={() => {
                                                setNoSymptoms(false);
                                                setNoMemory(false);
                                                setSymptoms(true);
                                            }}
                                            name="symptoms"
                                        />{' '}
                                        <Form.Check.Label>
                                            Symptoms first noticed around:
                                        </Form.Check.Label>
                                        <Form.Control.Feedback type="invalid">
                                            A date must be entered if symptoms
                                            are reported.
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                    <Row className="justify-content-end">
                                        <Col xs={11}>
                                            <Form.Control
                                                className={
                                                    symptoms ? '' : 'd-none'
                                                }
                                                required={symptoms}
                                                isValid={
                                                    validated && symptomsDate
                                                }
                                                isInvalid={
                                                    validated &&
                                                    symptoms &&
                                                    !symptomsDate
                                                }
                                                type="date"
                                                max={new Date()
                                                    .toISOString()
                                                    .substr(0, 10)}
                                                value={symptomsDate}
                                                onChange={(e) => {
                                                    setSymptomsDate(
                                                        e.target.value,
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="my-5 justify-content-end">
                            <Col xs="auto">
                                <Button
                                    type="submit"
                                    size="lg"
                                    variant="dark"
                                    className="px-5"
                                >
                                    <h2 className="my-1">Save</h2>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminAddTest;
