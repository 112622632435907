import React from 'react';
import '../../../assets/css/dashboard.css';
import { withRouter } from 'react-router-dom';
import { Card, CardDeck } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportD from 'highcharts/modules/export-data';
import RHC from 'react-highcharts';
exporting(Highcharts);
exportD(Highcharts);
const ReactHighcharts = RHC.withHighcharts(Highcharts);

const datesArr = [
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    '13th',
    '14th',
    '15th',
    '16th',
    '17th',
    '18th',
    '19th',
    '20th',
    '21st',
    '22nd',
    '23rd',
    '24th',
    '25th',
    '26th',
    '27th',
    '28th',
    '29th',
    '30th',
    '31st',
];

const hamburgerArr = [
    'viewFullscreen',
    'viewData',
    'separator',
    'downloadPDF',
    'downloadXLS',
    'downloadCSV',
];

const colorsArr = [
    '#D8CBFD',
    '#9672F9',
    '#C12E2A',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a',
];

const smallHeight = '75%';

const hotSpotsChart = {
    chart: {
        type: 'column',
        // events: {
        //     load: function () {
        //         var chart = this,
        //             r = chart.renderer; // We will use this renderer.

        //         r.button(
        //             '<b>Site<b>', // Text
        //             chart.plotLeft, // x
        //             10, // y
        //             function () {
        //                 // Fires on click
        //                 chart.series[0].update({
        //                     type: 'line',
        //                 });
        //             },
        //         ).add();
        //     },
        // },
    },
    title: {
        text: 'Location Data / "Hotspots"',
    },
    xAxis: {
        categories: [
            'Main Entrance',
            'Conference Room',
            'Break Room',
            'etc1',
            'etc2',
            'etc3',
        ],
    },
    yAxis: {
        title: {
            text: 'Individuals',
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: 'gray',
            },
        },
    },
    plotOptions: {
        column: {
            stacking: 'normal',
        },
    },
    series: [
        {
            name: 'Check Ins',
            data: [98, 35, 29, 7, 4, 0],
        },
        {
            name: 'Exposures',
            data: [30, 7, 3, 1, 0, 3],
        },
        {
            name: 'Positive Tests',
            data: [9, 2, 0, 0, 0, 1],
        },
    ],
    colors: colorsArr,
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 800,
                },
                chartOptions: {
                    series: [
                        {
                            name: 'Check Ins',
                            data: [98, 35, 29],
                        },
                        {
                            name: 'Exposures',
                            data: [30, 7, 3],
                        },
                        {
                            name: 'Positive Tests',
                            data: [9, 2, 0],
                        },
                    ],
                },
            },
        ],
    },
    exporting: {
        buttons: {
            contextButton: {
                text: 'EXPORT',
                symbol: '',
                menuItems: hamburgerArr,
            },
        },
    },
    navigation: {
        buttonOptions: {
            y: -10,
            x: 10,
            theme: {
                'stroke-width': 2,
                stroke: 'silver',
                r: 5,
            },
        },
    },
};

const dailyPosChart = {
    chart: {
        type: 'area',
        height: smallHeight,
    },
    title: {
        text: 'Daily Positive Reports',
    },
    subtitle: {
        text: 'Month to Date',
    },
    yAxis: {
        title: {
            text: '',
        },
    },
    xAxis: {
        categories: datesArr,
    },
    series: [
        {
            showInLegend: false,
            name: 'Reports',
            data: [
                8,
                11,
                9,
                16,
                12,
                11,
                13,
                9,
                11,
                15,
                12,
                9,
                7,
                9,
                6,
                4,
                8,
                5,
                3,
            ],
        },
    ],
    exporting: {
        buttons: {
            contextButton: {
                text: 'EXPORT',
                symbol: '',
                menuItems: hamburgerArr,
            },
        },
    },
    navigation: {
        buttonOptions: {
            y: -10,
            x: 10,
            theme: {
                'stroke-width': 2,
                stroke: 'silver',
                r: 5,
            },
        },
    },
};

const dailyCheckinChart = {
    chart: {
        height: smallHeight,
    },
    title: {
        text: 'Checking in today: 31.5%',
    },
    subtitle: {
        text:
            '<div class="small-title">31.5%</div><br><div class="small-sub" style="color:green">+5% from yesterday</div>',
        align: 'center',
        verticalAlign: 'middle',
        style: {
            textAlign: 'center',
        },
        x: 0,
        y: -10,
        useHTML: true,
    },
    series: [
        {
            type: 'pie',
            enableMouseTracking: false,
            innerSize: '80%',
            dataLabels: {
                enabled: false,
            },
            data: [
                {
                    y: 31.5,
                },
                {
                    y: 68.5,
                    color: '#e3e3e3',
                },
            ],
        },
    ],
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 110,
                },
                chartOptions: {
                    subtitle: {
                        text:
                            '<div class="small-title">22</div><br><div class="small-sub">112 not checked in</div>',
                    },
                },
            },
            {
                condition: {
                    maxWidth: 300,
                },
                chartOptions: {
                    subtitle: {
                        text: '',
                    },
                },
            },
        ],
    },
};

const weeklyCheckinChart = {
    chart: {
        height: smallHeight,
    },
    title: {
        text: 'Checked in weekly: 22',
    },
    subtitle: {
        text:
            '<div class="small-title">22</div><br><div class="small-sub">112 not checked in</div>',
        align: 'center',
        verticalAlign: 'middle',
        style: {
            textAlign: 'center',
        },
        x: 0,
        y: -10,
        useHTML: true,
    },
    series: [
        {
            type: 'pie',
            enableMouseTracking: false,
            innerSize: '80%',
            dataLabels: {
                enabled: false,
            },
            data: [
                {
                    y: 22,
                },
                {
                    y: 112,
                    color: '#e3e3e3',
                },
            ],
        },
    ],
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 110,
                },
                chartOptions: {
                    subtitle: {
                        text:
                            '<div class="small-title">22</div><br><div class="small-sub">112 not checked in</div>',
                    },
                },
            },
            {
                condition: {
                    maxWidth: 300,
                },
                chartOptions: {
                    subtitle: {
                        text: '',
                    },
                },
            },
        ],
    },
};

// const allCharts = [
//     hotSpotsChart,
//     dailyPosChart,
//     dailyCheckinChart,
//     weeklyCheckinChart,
// ];

// window.onresize = function () {
//     for (var i = 0; i < Highcharts.charts.length; i++) {
//         if (Highcharts.charts[i] !== undefined) {
//             console.log(Highcharts.charts[i].title);
//             Highcharts.charts[i].reflow();
//         }
//     }
//     // console.log(Object.getOwnPropertyNames(RHC.Highcharts.charts));
// };

class AdminDashboardPage extends React.Component {
    constructor() {
        super();
        this.state = {
            site: 'Site 1',
        };

        this.dropClick = this.dropClick.bind(this);
    }

    dropClick(e) {
        if (this.state.site !== e) this.setState({ site: e });
    }

    render() {
        return (
            <div className="content m-0 p-0 w-100 spaced">
                <h1 className="header rounded">Admin Dashboard</h1>
                <div className="container">
                    <div className="row justify-content-center">
                        <CardDeck>
                            <div className="col-md-12 col-lg-4 gap-b">
                                <Card border="dark" className="shadow">
                                    <Card.Body>
                                        <ReactHighcharts
                                            config={dailyPosChart}
                                        ></ReactHighcharts>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-12 col-lg-4 gap-b">
                                <Card border="dark" className="shadow">
                                    <Card.Body>
                                        <RHC config={dailyCheckinChart}></RHC>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-12 col-lg-4 gap-b">
                                <Card border="dark" className="shadow">
                                    <Card.Body>
                                        <RHC config={weeklyCheckinChart}></RHC>
                                    </Card.Body>
                                </Card>
                            </div>
                        </CardDeck>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <CardDeck>
                            <div className="col-lg-12 gap-b">
                                <Card border="dark" className="shadow">
                                    <Card.Body>
                                        <Card.Title
                                            style={{ textAlign: 'right' }}
                                        >
                                            <DropdownButton
                                                id="dropdown-basic-button"
                                                title={this.state.site}
                                            >
                                                <Dropdown.Item
                                                    onClick={(e) =>
                                                        this.dropClick(
                                                            e.target
                                                                .textContent,
                                                        )
                                                    }
                                                >
                                                    Site 1
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={(e) =>
                                                        this.dropClick(
                                                            e.target
                                                                .textContent,
                                                        )
                                                    }
                                                >
                                                    Site 2
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={(e) =>
                                                        this.dropClick(
                                                            e.target
                                                                .textContent,
                                                        )
                                                    }
                                                >
                                                    Site 3
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </Card.Title>
                                        <ReactHighcharts
                                            config={hotSpotsChart}
                                        ></ReactHighcharts>
                                    </Card.Body>
                                </Card>
                            </div>
                        </CardDeck>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AdminDashboardPage);
