/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import controller from '../../../lib/controller';
import clipboards from 'Images/clipboards.svg';
import InfoCard from '../check-in/components/InfoCard';
import gPhone from 'google-libphonenumber';

const PNF = gPhone.PhoneNumberFormat;
const phoneUtil = gPhone.PhoneNumberUtil.getInstance();

const severityColor = {
    high: '#FF734D99',
    medium: '#FFC44D99',
    low: '#EBF9F3',
};

const AdminReport = () => {
    const location = useLocation();
    const [data, setData] = useState(null);
    /* const [data, setData] = useState({
        estimatedExposures: 3,
        id: 1,
        postiveResult: true,
        report: [
            {
                affectedParty: '+50683121526',
                siteName: 'Site 1',
                locationName: 'Offica A',
                timestamp: '12/1/2020 14.00',
                risk: '.75',
            },
            {
                affectedParty: '+50683121542',
                siteName: 'Site 2',
                locationName: 'Offica B',
                timestamp: '12/1/2020 14.00',
                risk: '.42',
            },
            {
                affectedParty: '+50683121532',
                siteName: 'Site 3',
                locationName: 'Offica C',
                timestamp: '12/1/2020 14.00',
                risk: '.25',
            },
        ],
        resultDate: '2020-11-30',
        symptomOnset: '2020-11-29',
        testDate: '2020-11-31',
        testedParty: {
            id: '21',
            name: 'Justin',
            phoneNumber: '+50683121265',
        },
    }); */

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        const orgId = search.get('org');
        const reportId = search.get('id');

        const getData = async () => {
            const data = await controller.get_reports(orgId, reportId);

            const report = await controller.get_exposure_report(
                orgId,
                reportId,
            );
            data.report = report;

            const csv = `Affected Party, Site Name, Location Name, Timestamp, Risk\n${await controller.get_exposure_report(
                orgId,
                reportId,
                true,
            )}`;

            data.export = csv;

            const formattedNumber = phoneUtil.format(
                phoneUtil.parseAndKeepRawInput(data.testedParty.phoneNumber),
                PNF.NATIONAL,
            );
            data.testedParty.phoneNumber = formattedNumber;

            setData(data);
        };

        getData();
    }, []);

    const rows = () => {
        if (!data?.report.length) {
            return (
                <tr>
                    <td colSpan="7" className="bg-white border-0 px-10">
                        <InfoCard
                            cardClass="w-50 mx-auto"
                            imageClass="py-3 w-50 mx-auto"
                            image={clipboards}
                            bodyColor="white"
                        >
                            <Card.Title>
                                No data for {data?.testedParty.name}
                            </Card.Title>
                            <Card.Text>
                                There is no contact tracing data for this
                                employee. This may be because the employee has
                                not registered their device to the given phone
                                number.
                            </Card.Text>
                        </InfoCard>
                    </td>
                </tr>
            );
        } else {
            const rows = data.report?.map((report, index) => {
                const severity =
                    report.risk >= 0.666
                        ? 'high'
                        : report.risk <= 0.333
                        ? 'low'
                        : 'medium';
                const color = severityColor[severity];

                const formattedNumber = phoneUtil.format(
                    phoneUtil.parseAndKeepRawInput(report.affectedParty),
                    PNF.NATIONAL,
                );

                return (
                    <tr
                        style={{
                            backgroundColor: color,
                        }}
                        key={index}
                    >
                        <td className="align-middle">{formattedNumber}</td>
                        <td className="align-middle">{report.siteName}</td>
                        <td className="align-middle">{report.locationName}</td>
                        <td className="align-middle">{report.timestamp}</td>
                    </tr>
                );
            });

            return rows;
        }
    };

    return (
        <Container className="text-center">
            <h1 className="bg-dark text-white mt-5 py-5 rounded">
                Report for {data?.testedParty.name}
            </h1>
            <Container className="px-3 pb-3 text-left">
                <Row>
                    <Col xs="auto">
                        <h2>
                            Name:{' '}
                            <small className="pl-1">
                                {data?.testedParty.name}
                            </small>
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <h3>
                            Phone:{' '}
                            <small className="pl-1">
                                {data?.testedParty.phoneNumber}
                            </small>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <h3>
                            Date of Test:{' '}
                            <small className="pl-1">{data?.testDate}</small>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <h3>
                            Date of Test Result:{' '}
                            <small className="pl-1">{data?.resultDate}</small>
                        </h3>
                    </Col>
                </Row>

                <Row className="align-items-center mt-3 mb-2">
                    <Col className="pr-0" xs="auto">
                        <h3 className="my-0">Potential Exposures</h3>
                        <h6>
                            NOTE: Individuals may have been exposed to COVID-19
                            on multiple occasions. This chart displays the site,
                            location, and time where the Zerobase model has
                            calculated the highest chance of exposure.
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table responsive className="mb-5">
                            <thead
                                className={
                                    !data?.report?.length ? 'd-none' : ''
                                }
                            >
                                <tr>
                                    <th className="text-dark">Phone</th>
                                    <th className="text-dark">Exposure Site</th>
                                    <th className="text-dark">
                                        Exposure Location
                                    </th>
                                    <th className="text-dark">Exposure Time</th>
                                </tr>
                            </thead>
                            <tbody>{rows()}</tbody>
                        </Table>
                    </Col>
                </Row>
                {data ? (
                    <Row className="justify-content-end my-5">
                        <Col xs="auto">
                            <Button
                                as={CSVLink}
                                filename={`Exposure_Report_${data.testedParty.name}_${data.testDate}.csv`}
                                data={data.export}
                                variant="dark"
                            >
                                Export as CSV
                            </Button>
                        </Col>
                    </Row>
                ) : null}
            </Container>
        </Container>
    );
};

export default withRouter(AdminReport);
