import React from 'react';
import { string, node, bool } from 'prop-types';
import { Card, Spinner } from 'react-bootstrap';
import InfoCard from './InfoCard';

const StatusCard = ({
    cardClass,
    statusText,
    showSpinner,
    image,
    imageClass,
    bodyColor,
}) => {
    return (
        <InfoCard
            cardClass={cardClass}
            imageClass={imageClass}
            image={image}
            bodyColor={bodyColor}
        >
            <Card.Text>{statusText}</Card.Text>
            <Spinner
                className={showSpinner ? 'mx-auto d-block' : 'd-none'}
                animation="border"
                variant="info"
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        </InfoCard>
    );
};

StatusCard.propTypes = {
    cardClass: string,
    statusText: string.isRequired,
    showSpinner: bool,
    image: node,
    imageClass: string,
    bodyColor: string,
};

StatusCard.defaultProps = {
    statusText: 'Processing...',
    showSpinner: false,
};

export default StatusCard;
