import React from 'react';

class PrivacyLanding extends React.Component {
    render() {
        return (
            <div class="content pt-0 pb-0 mb-0">
                <div class="container-fluid w-100 p-0 mb-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div
                                    class="hero-img-height hero-img-cover-center"
                                    style={{
                                        backgroundImage:
                                            'url(/assets/img/info/ZBHero_privacy.jpg)',
                                    }}
                                    alt="Zerobase logo over network of dots connected with straight lines."
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row m-4 mb-md-6">
                        <div class="col-lg-8 col-12 pr-lg-5">
                            <h1
                                class="mb-5"
                                style={{ color: '#1B2935', fontSize: '400%' }}
                            >
                                Privacy First
                            </h1>
                        </div>
                    </div>
                    <div class="row m-4 mb-md-5">
                        <div class="col-lg-6 col-12 pr-lg-5">
                            <p
                                class="h1 lh-180 mb-3"
                                style={{ fontSize: '24px' }}
                            >
                                Zerobase is designed from the ground up to put
                                your privacy first.
                            </p>
                        </div>
                        <div class="col-lg-6 col-12">
                            <p class="lead lh-180" style={{ fontSize: '24px' }}>
                                Unlike other contact tracing mechanisms
                                developed so far, Zerobase does not track GPS
                                locations or require downloading an application
                                to your phone.
                            </p>
                            <p class="lead lh-180" style={{ fontSize: '24px' }}>
                                Instead, our QR network securely and privately
                                traces the spread of COVID-19 throughout a
                                community without tracking your personal
                                identity or your location at all hours of the
                                day.
                            </p>
                        </div>
                    </div>
                    <div class="row m-4 mb-5">
                        <div class="col-lg-6 col-12 pr-lg-5">
                            <p
                                class="h1 lh-180 mb-3"
                                style={{ fontSize: '24px' }}
                            >
                                Zerobase was built to help real people.
                            </p>
                        </div>
                        <div class="col-lg-6 col-12">
                            <p class="lead lh-180" style={{ fontSize: '24px' }}>
                                The best way for you to stay notified in real
                                time of possible exposure is to securely share
                                your phone number. If we don't have access to
                                your phone number, we'll still try to notify you
                                the next time you check in to any location, but
                                it will take longer to let you know that you may
                                have been exposed.
                            </p>
                            <p class="lead lh-180" style={{ fontSize: '24px' }}>
                                Any information provided is encrypted and used
                                only to keep you informed.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    class="container-fluid w-100 p-0 mb-0"
                    style={{ backgroundColor: '#65C6E1' }}
                >
                    <div
                        class="card shadow-none border-0"
                        style={{ backgroundColor: '#65C6E1' }}
                    >
                        <div class="row justify-content-center p-6">
                            <div class="col-md-5 col-12 text-left">
                                <h2
                                    class="text-white font-weight-normal"
                                    style={{ color: 'white', fontSize: '200%' }}
                                >
                                    <p>We do not work with advertisers.</p>
                                    <p>We do not sell your information.</p>
                                    <p>
                                        We adhere to industrial standards of
                                        data security.
                                    </p>
                                </h2>
                            </div>
                            <div class="col-md-5 col-12 text-left">
                                <h2>
                                    <p
                                        class="font-weight-normal"
                                        style={{
                                            color: '#1B2935',
                                            fontSize: '150%',
                                        }}
                                    >
                                        We believe firmly in:
                                    </p>
                                    <p
                                        class="font-weight-normal"
                                        style={{
                                            color: '#1B2935',
                                            fontSize: '200%',
                                        }}
                                    >
                                        <strong>
                                            “Big Data without Big Brother.”
                                        </strong>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container d-flex align-items-center text-center text-lg-left py-5 py-lg-6">
                    <div class="col px-0">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 text-center">
                                <a
                                    class="btn btn-secondary btn-lg h2 h1 mb-2"
                                    href="/privacy-policy"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    style={{
                                        wordWrap: 'break-word',
                                        fontSize: '36px',
                                    }}
                                >
                                    Privacy Policy
                                </a>
                                <h6 class="font-weight-light text-muted">
                                    Last updated: 6 April 2020
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyLanding;
