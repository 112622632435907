import React from 'react';

class TestingsiteLanding extends React.Component {
    render() {
        return (
            <div class="content m-0 p-0 w-100">
                <div class="container-fluid w-100 p-0 mb-2 mb-md-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div
                                    class="hero-img-height hero-img-cover-top"
                                    style={{
                                        backgroundImage:
                                            'url(/assets/img/info/ZB_containers_testing.jpg)',
                                        filter: 'brightness(70%)',
                                    }}
                                    alt="Nurse with clip board talking to patient."
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row m-2 mb-6">
                        <div class="title col-12 col-md-6">
                            <h2 class="align-center pb-3 mbr-fonts-style display-3">
                                Healthcare and Testing Sites
                            </h2>
                            <h3 class="mbr-section-subtitle align-center mbr-light mbr-fonts-style display-5">
                                Support your community in the fight against
                                COVID-19.
                            </h3>
                        </div>
                    </div>
                    <div class="row m-2 mb-md-5">
                        <div class="col-lg-6 col-12 pr-lg-5">
                            <p
                                class="h1 lh-180 mb-3"
                                style={{ fontSize: '30px' }}
                            >
                                Zerobase is a free, HIPAA/GDPR-compliant contact
                                tracing platform that helps healthcare
                                providers, local officials, and individuals
                                contain and eliminate COVID-19.
                            </p>
                        </div>
                        <div class="col-lg-6 col-12">
                            <p class="lead lh-180" style={{ fontSize: '24px' }}>
                                Providers who administer COVID-19 tests can
                                deploy Zerobase with nothing more than paper
                                printouts.
                            </p>
                            <p class="lead lh-180" style={{ fontSize: '24px' }}>
                                Because of this, Zerobase can be used at any
                                freestanding, mobile, and temporary testing
                                site.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="media-container-row mt-6">
                        <div class="col-lg-12 col-12">
                            <div class="media-container-row">
                                <div class="media-content">
                                    <div class="mbr-section-text">
                                        <p
                                            class="mbr-text mb-0 mbr-fonts-style"
                                            style={{ fontSize: '24px' }}
                                        >
                                            <strong>
                                                Our contact tracing platform
                                                monitors
                                            </strong>{' '}
                                            the spread of the virus throughout a
                                            community and can provide
                                            individualized risk exposure scores,
                                            allowing providers to deploy scarce
                                            testing and protective resources
                                            most efficiently.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="media-container-row mt-6">
                        <div class="col-lg-12 col-12">
                            <div class="media-container-row">
                                <div class="media-content">
                                    <div class="mbr-section-text">
                                        <p
                                            class="mbr-text mb-0 mbr-fonts-style"
                                            style={{ fontSize: '24px' }}
                                        >
                                            <strong>
                                                Unlike other contact tracing
                                                mechanisms
                                            </strong>{' '}
                                            developed so far, Zerobase does not
                                            track GPS locations, require
                                            downloading an app, or necessitate
                                            installing intrusive technology.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container mt-6">
                        <div class="row m-4 p-5 mt-5 border-top border-secondary pb-4 pt-4">
                            <div class="col-12" style={{ fontSize: '48px' }}>
                                <strong>Here's how it works:</strong>
                            </div>
                        </div>
                        <div class="row align-middle">
                            <div class="col-lg-3 col-3 text-center">
                                <p style={{ fontSize: '76px' }}>
                                    <strong>1</strong>
                                </p>
                            </div>
                            <div class="col-lg-9 col-9 align-middle my-auto">
                                <p
                                    class="align-middle"
                                    style={{ fontSize: '24px' }}
                                >
                                    Upon intake, each patient tested for
                                    COVID-19 is given a sheet of paper with a
                                    Zerobase QR code on it to scan. Each code
                                    sheet is unique and anonymous. Batches of
                                    code sheets can be printed and used at
                                    testing centers or distributed to mobile or
                                    temporary sites.
                                </p>
                            </div>
                        </div>
                        <div class="row align-middle">
                            <div class="col-lg-3 col-3 text-center">
                                <p style={{ fontSize: '76px' }}>
                                    <strong>2</strong>
                                </p>
                            </div>
                            <div class="col-lg-9 col-9 align-middle my-auto">
                                <p
                                    class="align-middle"
                                    style={{ fontSize: '24px' }}
                                >
                                    Using the patient’s trace history,
                                    Zerobase’s technology anonymously analyzes
                                    community networks of interaction to
                                    calculate a risk score for the patient based
                                    on their check-in history.
                                </p>
                            </div>
                        </div>
                        <div class="row align-middle">
                            <div class="col-lg-3 col-3 text-center">
                                <p style={{ fontSize: '76px' }}>
                                    <strong>3</strong>
                                </p>
                            </div>
                            <div class="col-lg-9 col-9 align-middle my-auto">
                                <p
                                    class="align-middle"
                                    style={{ fontSize: '24px' }}
                                >
                                    Tracing and test results will be combined to
                                    identify individuals in a community who
                                    visited the same places as the patient
                                    around the same time.
                                </p>
                            </div>
                        </div>
                        <div class="row align-middle">
                            <div class="col-lg-3 col-3 text-center">
                                <p style={{ fontSize: '76px' }}>
                                    <strong>4</strong>
                                </p>
                            </div>
                            <div class="col-lg-9 col-9 align-middle my-auto">
                                <p
                                    class="align-middle"
                                    style={{ fontSize: '24px' }}
                                >
                                    Public health officials can then notify
                                    individuals who are registered for contact
                                    who may have been exposed and advise them to
                                    self-quarantine or get tested, even before
                                    they exhibit symptoms.
                                </p>
                            </div>
                        </div>
                        <div class="media-container-row mt-6">
                            <div class="col-lg-12 col-12">
                                <div class="media-container-row">
                                    <div class="media-content">
                                        <div class="mbr-section-text">
                                            <p
                                                class="mbr-text mb-0 mbr-fonts-style"
                                                style={{ fontSize: '24px' }}
                                            >
                                                <strong>
                                                    Our team will work closely
                                                    with you to ensure a smooth
                                                    rollout in your facilities.
                                                </strong>{' '}
                                                Zerobase is designed to support
                                                our vital healthcare partners
                                                and help them stay safe as they
                                                provide the critical emergency
                                                care that our communities depend
                                                on.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div class="container">
                            <div class="row m-4 p-5 mt-5 border-top border-secondary pb-4 pt-4">
                                <div class="col-lg-8 col-12 pr-lg-5 mt-6">
                                    <p
                                        class="h1 lh-180 mb-3"
                                        style={{ fontSize: '24px' }}
                                    >
                                        Support your community in the fight
                                        against COVID-19.
                                    </p>
                                    <p class="h2 font-weight-normal">
                                        Zerobase is free, it’s ready, and it
                                        saves lives.
                                    </p>
                                </div>
                                <div class="col-lg-4 col-12 align-middle mt-6">
                                    <a
                                        class="btn btn-primary btn-lg align-middle"
                                        href="/healthcare/register"
                                        id="register-healthcare"
                                    >
                                        Register your site
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TestingsiteLanding;
